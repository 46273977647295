import { FileUploader } from "baseui/file-uploader";
import { FunctionComponent } from "react";
import { uploadImage } from "../../services/image/ImageService";
import ContentWrapper from "../styled/ContentWrapper";
import "./UploadImage.css";

interface UploadImageProps {
    imageUrl: string;
    onSuccess: (url:string) => void;
    label?:string;
}
 
const UploadImage: FunctionComponent<UploadImageProps> = (props) => {
  const {imageUrl, onSuccess, label} = props;

  const handleUpload = (files: File[]) => {
    if(files[0]) {
      uploadImage(files[0]).then((url) => {
        onSuccess(url);
      });
    }
  };

  return ( <>
    <ContentWrapper $noPad>
      <ContentWrapper $noPad>
        <header>
          <h3>{label || "Välj bild"}</h3>
          {imageUrl && (
            <img src={imageUrl} />
          )}
        </header>
      </ContentWrapper>
      <FileUploader
        onDrop={(files) => handleUpload(files)}
        accept=".jpg, .png, .jpeg"
        maxSize={undefined}
        minSize={undefined}
        errorMessage=""
      />
    </ContentWrapper>
  </> );
};
 
export default UploadImage;