import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Cell, Grid } from "baseui/layout-grid";
import { HeadingMedium } from "baseui/typography";
import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReduxService } from "../../../core/services/Redux";
import Btn from "../../../shared/components/styled/Btn";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import PlainCard from "../../../shared/components/styled/plain-card/PlainCard";
import PlainCardFooter from "../../../shared/components/styled/plain-card/PlainCardFooter";
import { Themes } from "../../../shared/enums/Themes.enum";
import { User } from "../../../shared/interfaces/User.interface";
import { updateUser } from "../../../shared/services/user/UserService";

interface EditUserProps {
  user: User;
}

const EditUser: FunctionComponent<EditUserProps> = (props) => {
  const { user } = props;
  const [displayName, setDisplayName] = useState<string>("");
  const [zone, setZone] = useState<string>("1");
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    ReduxService.instance.setTheme(Themes.User);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted && user) {
      if (user?.displayName) {
        setDisplayName(user.displayName);
      }
      if (user?.zone) {
        setZone(user.zone.toString());
      }
    }

    return () => {
      mounted = false;
    };
  }, [user]);

  const handleNameChange = (event: any) => {
    setIsDirty(true);
    setDisplayName(event.target.value);
  };
  const handleZoneChange = (zone: string) => {
    setIsDirty(true);
    setZone(zone);
  };

  const onSubmit = () => {
    updateUser(user.id, { zone: +zone, displayName: displayName }).then(
      (result) => {
        if (result) {
          setIsDirty(false);
        }
      }
    );
  };

  return !user ? (
    <div>Loading</div>
  ) : (
    <Grid>
      <Cell span={[12]}>
        <ContentWrapper>
          <HeadingMedium>{user.displayName || user.name}</HeadingMedium>
          <small>
            {user.name} / {user.email}
          </small>
        </ContentWrapper>
      </Cell>
      <Cell span={[12]}>
        <PlainCard>
          <ContentWrapper $noPad></ContentWrapper>
          <ContentWrapper $noPad>
            <Grid>
              <Cell span={[4, 8, 6]}>
                <FormControl label="Visningsnamn">
                  <Input
                    value={displayName}
                    onChange={handleNameChange}
                  ></Input>
                </FormControl>
              </Cell>
              <Cell span={[4, 8, 6]}>
                <FormControl label="Växtzon">
                  <Input
                    value={zone}
                    onChange={(e: any) => setZone(e.target.value)}
                    type="number"
                    min={1}
                    max={8}
                  />
                </FormControl>
              </Cell>
            </Grid>
          </ContentWrapper>
          <PlainCardFooter>
            <Btn color="success" disabled={!isDirty} onClick={onSubmit}>
              Spara
            </Btn>
          </PlainCardFooter>
        </PlainCard>
      </Cell>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};

export default connect(mapStateToProps)(EditUser);
