
import { Store } from "redux";
import { Themes } from "../../shared/enums/Themes.enum";
import { User } from "../../shared/interfaces/User.interface";
import { setTheme } from "../../state/theme/actions";
import { setUser } from "../../state/user/actions";


class ReduxService {
  static _instance: ReduxService;
  static get instance(): ReduxService{
    this._instance = this._instance || new ReduxService();
    return this._instance;
  }

  

  private store?: Store;

  public assignStore(store: Store) {
    this.store = store;
  }

  public setUser(data: User | null) {
    this.store?.dispatch(setUser(data));
  }

  public getUser(): User {
    return this.store?.getState().user.data;
  }
  public setTheme(data: Themes) {
    this.store?.dispatch(setTheme(data));
  }

  public getTheme(): Themes {
    return this.store?.getState().theme.data;
  }
}

export {ReduxService};