import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp } from "firebase/firestore";
import { ReduxService } from "../../core/services/Redux";
import { PlantSubCategory } from "../interfaces/PlantSubCategory.interface";


class PlantSubCategoryConverter implements FirestoreDataConverter<PlantSubCategory> {


  toFirestore(event: PartialWithFieldValue<PlantSubCategory>):DocumentData {
    const user = ReduxService.instance.getUser();
    return {
      ...event,
      createdBy: event.createdBy || user.id, 
      createdAt: event.createdAt || serverTimestamp(),
      updatedBy: user.id, 
      updatedAt: serverTimestamp()
    };
  }


  fromFirestore(snap: QueryDocumentSnapshot): PlantSubCategory {
    const data = snap.data();
    const id = snap.id;
    return {...data, id} as PlantSubCategory;
  }
}

export default PlantSubCategoryConverter;