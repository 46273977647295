import { KIND as ButtonKind } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import {
  Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE
} from "baseui/modal";
import React, { FunctionComponent, useState } from "react";
import { savePlantSubCategory } from "../../../../../shared/services/plant-sub-categories/PlantSubCategoriesService";

interface AddSubCategoryProps  {
    isOpen: boolean
    setIsOpen: (value: boolean) => void;
    parentId: string
}

const AddSubCategory: FunctionComponent<AddSubCategoryProps> = (props) => {
  const [name, setName] = useState<string>("");
  const {isOpen, setIsOpen, parentId} = props;
  
  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const resetAll = () => {
    setName("");
  };

  const onCancel = () => {
    resetAll();
    setIsOpen(false);
  };

  const onSubmit = () => {
    savePlantSubCategory(parentId, {name:name}).then(() => {
      resetAll();
      setIsOpen(false);
    });
  };

  return (
    <Modal
      onClose={() => setIsOpen(false)}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
    >
      <ModalHeader>Lägg till underkategori</ModalHeader>
      <ModalBody>
        <FormControl label="Namn" >
          <Input  id="category-name" name="name" type="text" value={name} onChange={handleNameChange}></Input>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={ButtonKind.tertiary} onClick={onCancel}>
          Ångra
        </ModalButton>
        <ModalButton disabled={!name} onClick={onSubmit}>Lägg till</ModalButton>
      </ModalFooter>
    </Modal>
  );
};
export default AddSubCategory;