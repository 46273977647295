import { StatefulMenu } from "baseui/menu";
import { User } from "firebase/auth";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "../../../../shared/interfaces/MenuItem.interface";


interface CultureMenuProps {
    user?: User;
    close: () => void;
}
 
const CultureMenu: FunctionComponent<CultureMenuProps> = (props) => {
  const {close} = props;
  const nav = useNavigate();
  const items: MenuItem[] = [
    {link:"/culture", label:"Mina odlingar"},
    {link:"/culture/new", label:"Ny odling..."},
  ];

  const handleMenuChange = (item: MenuItem):void  => {
    close();
    nav(item.link);
  };

  return ( 
    <StatefulMenu 
      items={items} 
      onItemSelect={({item}) => handleMenuChange(item)}
      overrides={{ 
        Option: {
          props: {
            getItemLabel: (item:MenuItem)=> item.label
          }
        }
      }}/>
  );
};
 
export default CultureMenu;