import { DisplayXSmall, HeadingMedium, LabelSmall } from "baseui/typography";
import { FunctionComponent, useEffect, useState } from "react";
import Btn from "../../../../../shared/components/styled/Btn";
import ContentWrapper from "../../../../../shared/components/styled/ContentWrapper";
import PlainCard from "../../../../../shared/components/styled/plain-card/PlainCard";
import PlainCardFooter from "../../../../../shared/components/styled/plain-card/PlainCardFooter";
import { PlantCategory } from "../../../../../shared/interfaces/PlantCategory.interface";
import { PlantSubCategory } from "../../../../../shared/interfaces/PlantSubCategory.interface";
import { getPlantType } from "../../../../../shared/services/plant-type/PlantTypeService";
import FindPlantVarietyType from "./components/find-plant-variety-type/FindPlantVarietyType";


interface SetPlantVarietyTypeProps {
    typeId: string;
    setTypeId: (id: string) => void;
}
 
const SetPlantVarietyType: FunctionComponent<SetPlantVarietyTypeProps> = (props) => {
  const {typeId, setTypeId} = props;
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [cat, setCat] = useState<PlantCategory>();
  const [subCat, setSubCat] = useState<PlantSubCategory>();
  const plantType = getPlantType(typeId);

  useEffect(() => {
    if(plantType && plantType.categories && plantType.categories[0]) {
      const category = plantType.categories[0] as PlantCategory;
      setCat(category);
      if(category.subCategories && category.subCategories[0]) {
        setSubCat(category.subCategories[0]);
      }
    }
  }, [plantType]);

  return !setTypeId ? (<div>loading</div>) : ( 
    <div>
      <PlainCard>
        <ContentWrapper $noPad>
          <LabelSmall>Växttyp: </LabelSmall>
          <small>{cat && `${cat.name} /`} {subCat && `${subCat.name}`}</small>
          <HeadingMedium>{plantType.name}</HeadingMedium>
        </ContentWrapper>
        <PlainCardFooter>
          <Btn color="accent" onClick={() => setShowPicker(true)}>Välj typ</Btn>
        </PlainCardFooter>
      </PlainCard>
      <FindPlantVarietyType isOpen={showPicker} setIsOpen={(value) => setShowPicker(value)} setPickedType={(id) => setTypeId(id)}></FindPlantVarietyType>
    </div>
  );
};
 
export default SetPlantVarietyType;