import { addDoc, collection, doc, getDoc, getFirestore, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import app, { auth } from "../../../core/services/Firebase";
import CultureConverter from "../../converters/CultureConverter";
import PlantVarietyConverter from "../../converters/PlantVarietyConverter";
import { PublishingStatus } from "../../enums/PublishingStatus.enum";
import { Culture } from "../../interfaces/Culture.interface";
import { getPlantVarieties } from "../plant-variety/PlantVarietyService";

const db =  getFirestore(app);
const converter = new CultureConverter();
const plantVarietyConverter = new PlantVarietyConverter();

export async function saveCulture(data: Partial<Culture>): Promise<Culture>  {
  const query = collection(db, "cultures").withConverter(converter);
  
  const plantVarieties = data.plantVarieties?.map(plantVariety => {
    return doc(db, "plantVariety", plantVariety.id).withConverter(plantVarietyConverter);
  });
  if(plantVarieties) {
    data.plantVarieties = plantVarieties;
  }

  const pots = data.pots?.map(pot => {
    const rows = pot.rows;
    const rowKeys = Object.keys(rows);
    rowKeys.forEach(rowKey => {
      const columnKeys = Object.keys(rows[+rowKey]);
      columnKeys.forEach(columnKey => {
        rows[+rowKey][+columnKey].forEach((slot, index) => {
          if(slot.plantVariety) {
            rows[+rowKey][+columnKey][index].plantVariety = doc(db, "plantVariety", slot.plantVariety.id).withConverter(plantVarietyConverter);
          }
        });
      });
    });
    return {...pot, status:pot.status || PublishingStatus.Published, rows};
  }); 

  data.pots = pots;

  let docRef;
  try {
    docRef = await addDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }
  let snapshot;
  try {
    snapshot = await getDoc(docRef.withConverter(converter));
  } catch (error) {
    return Promise.reject(error);
  }
  const result = snapshot.data();
  if(result) {
    return Promise.resolve(result);
  } else {
    return Promise.reject("No culture returned");
  }
}

export async function updateCulture(id:string, data: Partial<Culture>): Promise<boolean>  {
  const query = doc(db, "cultures", id).withConverter(converter);
  
  const plantVarieties = data.plantVarieties?.map(plantVariety => {
    return doc(db, "plantVariety", plantVariety.id).withConverter(plantVarietyConverter);
  });

  if(plantVarieties) {
    data.plantVarieties = plantVarieties;
  }

  const pots = data.pots?.map(pot => {
    const rows = pot.rows;
    const rowKeys = Object.keys(rows);
    rowKeys.forEach(rowKey => {
      const columnKeys = Object.keys(rows[+rowKey]);
      columnKeys.forEach(columnKey => {
        rows[+rowKey][+columnKey].forEach((slot, index) => {
          if(slot.plantVariety) {
            rows[+rowKey][+columnKey][index].plantVariety = doc(db, "plantVariety", slot.plantVariety.id).withConverter(plantVarietyConverter);
          }
        });
      });
    });
    return {...pot, status:pot.status || PublishingStatus.Published, rows};
  }); 

  data.pots = pots;

 
  try {
    await setDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }
  

  return Promise.resolve(true);
  
}
    
export function getMyCultures(): Culture[] {
  const userId = auth.currentUser?.uid;
  const [cultures, setCultures] = useState<Culture[]>([]);
  const allVarieties = getPlantVarieties();
  if(!userId) {
    return [];
  }
  
  const col = collection(db, "cultures").withConverter(converter);
        
  useEffect(() => {
    onSnapshot(query(col, where("owner", "==", userId), where("status", "not-in", [PublishingStatus.Deleted, PublishingStatus.Archived])), (querySnap) => {
      const data = querySnap.docs.map(doc => doc.data());
      const newCultures = data.map(culture => {
        const plantVarieties = allVarieties.filter(variety => culture.plantVarieties?.map(variety => variety.id).includes(variety.id));
        return {...culture, plantVarieties};
      });
      
      newCultures.sort((a,b) =>  a.updatedAt > b.updatedAt ? 1 : -1);
      setCultures(newCultures);
    });              
  }, [userId, allVarieties]);

  

        
  return cultures;
}