import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp } from "firebase/firestore";
import { ReduxService } from "../../core/services/Redux";
import { PublishingStatus } from "../enums/PublishingStatus.enum";
import { Culture } from "../interfaces/Culture.interface";
class CultureConverter implements FirestoreDataConverter<Culture> {


  toFirestore(event: PartialWithFieldValue<Culture>): DocumentData { 
    const user = ReduxService.instance.getUser();
    return {
      ...event, 
      owner: event.owner || user.id, 
      createdAt: event.createdAt || serverTimestamp(),
      updatedBy: user.id, 
      updatedAt: serverTimestamp(),
      status: event.status || PublishingStatus.Published
    };
  }
  fromFirestore(snap: QueryDocumentSnapshot): Culture {
    const data = snap.data() as Culture;
    const id = snap.id;
    const pots = data.pots.map(pot => {
      const rows = pot.rows;
      Object.keys(rows).forEach((rowKey) => {
        Object.keys(rows[+rowKey]).map(potKey => {
          rows[+rowKey][+potKey].sort((a,b) => (a.changeDate > b.changeDate ? -1 : 1));
        });
      });
      
      return {...pot, rows};
    });
    data.comments.sort((a,b) => (a.date > b.date ? -1 : 1));
    data.place.sort((a,b) => (a.changeDate > b.changeDate ? -1 : 1));
    
    return {...data, pots, id};
 
  }
}

export default CultureConverter;