import { addDoc, collection, deleteDoc, doc, DocumentReference, getDoc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { app } from "../../../core/services/Firebase";
import PlantCategoryConverter from "../../converters/PlantCategoryConverter";
import { PlantCategory } from "../../interfaces/PlantCategory.interface";
import { PlantSubCategory } from "../../interfaces/PlantSubCategory.interface";
import { getPlantSubCategories } from "../plant-sub-categories/PlantSubCategoriesService";

const db =  getFirestore(app);


const converter = new PlantCategoryConverter();

export function getPlantCategories(): PlantCategory[] {
  const [cats, setCats] = useState<PlantCategory[]>([]);
  const subCats = getPlantSubCategories();
  
  const col = collection(db, "plantCategory").withConverter(converter);
    
  useEffect(() => {
    onSnapshot(col, (querySnap) => {
      const data = querySnap.docs.map(doc => doc.data());
      if(data) {
        let newData = data;
        if(subCats) {
          newData = data.map(cat => {          
            const subs = subCats.filter(sub => {
              const parentCat = sub?.parent as DocumentReference<PlantSubCategory>;
              if(parentCat && parentCat?.id && cat?.id) {
                return parentCat.id === cat.id;
              } else {return false;}
            });
            if(subs) {
              return {...cat, subCategories:subs};
            } else {
              return cat;
            }
          });
        }
        setCats(newData);
      }
    });
             
  }, [ subCats]);    
  return cats;
}
  
  
export function getPlantCategory(id: string): PlantCategory {
  const [cat, setCat] = useState<PlantCategory>({name:"", id:""});
  const [query, setQuery] = useState<DocumentReference<PlantCategory>>();
  const subCats = getPlantSubCategories(id);
  
  useEffect(() => {
    if(id) {
      setQuery(doc(db, "plantCategory", id).withConverter(converter));
    }
  }, [id]);
  
  useEffect(() => {
    if(query) {
      onSnapshot(query, (doc) => {
        const data = doc.data();
        if(data) {
          if(subCats) {
            data.subCategories = subCats;
          }
          setCat(data);
        }
      }); 
    }
  }, [query, subCats]);
  
  return cat;
    
}
  
export async function savePlantCategory(data: Partial<PlantCategory>): Promise<PlantCategory>  {
  const query = collection(db, "plantCategory").withConverter(converter);

  let docRef;
  try {
    docRef = await addDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }
  let snapshot;
  try {
    snapshot = await getDoc(docRef.withConverter(converter));
  } catch (error) {
    return Promise.reject(error);
  }
  const result = snapshot.data();
  if(result) {
    return Promise.resolve(result);
  } else {
    return Promise.reject("No cat returned");
  }
}
  
export async function  updatePlantCategory(id:string, data: Partial<PlantCategory>): Promise<boolean>  {
  const query = doc(db, "plantCategory", id).withConverter(converter);
  try {
    await setDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }
  return Promise.resolve(true);
}
  
export async function deletePlantCategory(id:string): Promise<boolean>  {
  const query = doc(db, "plantCategory", id);
  
  try {
    await deleteDoc(query);
  } catch (error) {
    return Promise.reject(error);
  }
    
  return Promise.resolve(true);
}