import { FormControl } from "baseui/form-control";
import { Option, Select, SIZE, Value } from "baseui/select";
import { FunctionComponent, useEffect, useState } from "react";
import PlainCard from "../../../../../shared/components/styled/plain-card/PlainCard";
import { getPlantCategories } from "../../../../../shared/services/plant-categories/PlantCategoriesService";
import { getPlantSubCategories } from "../../../../../shared/services/plant-sub-categories/PlantSubCategoriesService";


interface SetPlantTypeCategoriesProps {
    category:string;
    setCategory:(id:string) => void;
    subCategory: string;
    setSubCategory:(id:string) => void;
}
 
const SetPlantTypeCategories: FunctionComponent<SetPlantTypeCategoriesProps> = (props) => {
  const {category, setCategory, subCategory, setSubCategory} = props;
  const categories = getPlantCategories();
  const subCategories = getPlantSubCategories(category);
  const [catOptions, setCatOptions] = useState<Option[]>([{label:"", id:""}]);
  const [subCatOptions, setSubCatOptions] = useState<Option[]>([{label:"", id:""}]);
 
  useEffect(() => {
    let mounted = true;
    if(mounted) {
      setCatOptions(categoryOptions());
    }
    return() => {mounted=false;};
  }, [categories]);
  
  useEffect(() => {
    let mounted = true;
    if(mounted) {
      setSubCatOptions(subCategoryOptions());
    }
    return() => {mounted=false;};
  }, [category, subCategories]);

  const categoryValue = (): Value => {
    return categoryOptions().find(option => option.id === category) as Value;
  };

  const categoryOptions = (): Option[] => {
    return  categories.map(cat => ({label:cat.name, id:cat.id})) || [];
  };

  const setCategoryValue = (value: Value) => {
    if(value[0] && value[0].id) {
      setCategory(value[0].id as string);
    }
  };

  const subCategoryValue = (): Value => {
    return subCategoryOptions().find(option => option.id === subCategory) as Value;
  };

  const subCategoryOptions = (): Option[] => {
    return  subCategories.filter(cat => cat?.parent?.id === category).map(cat => ({label:cat.name, id:cat.id})) || [];
  };

  const setSubCategoryValue = (value: Value) => {
    if(value[0] && value[0].id) {
      setSubCategory(value[0].id as string);
    }
  };
  


  return !categories && !subCategories ? (<div>loading</div>):(
    <div>
      <PlainCard>
        <FormControl label="Kategori">
          <Select
            value={categoryValue()}
            multi={false}
            onChange={params => setCategoryValue(params.value)}
            size={SIZE.default}
            options={catOptions}
            placeholder=""
            
           
          />
        </FormControl>
        <FormControl label="Underkategori">
          <Select
            value={subCategoryValue()}
            multi={false}
            onChange={params => setSubCategoryValue(params.value)}
            size={SIZE.default}
            options={subCatOptions}
            placeholder=""
           
          />
        </FormControl>
      </PlainCard>
    </div>
  );
};
 
export default SetPlantTypeCategories;