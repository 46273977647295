import { ThemeProvider, useStyletron } from "baseui";
import { KIND, SHAPE, SIZE } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { DatePicker } from "baseui/datepicker";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Cell, Grid } from "baseui/layout-grid";
import { ListItem, ListItemLabel } from "baseui/list";
import { ProgressSteps, Step } from "baseui/progress-steps";
import { Textarea } from "baseui/textarea";
import { FunctionComponent, useEffect, useState } from "react";
import { GiGreenhouse } from "react-icons/gi";
import { RiCalendar2Fill, RiSeedlingFill } from "react-icons/ri";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { ReduxService } from "../../../core/services/Redux";
import { CultureTheme } from "../../../core/theme/CultureTheme";
import Pot from "../../../shared/components/pot/Pot";
import Btn from "../../../shared/components/styled/Btn";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import List from "../../../shared/components/styled/List";
import PlainCard from "../../../shared/components/styled/plain-card/PlainCard";
import PlainCardFooter from "../../../shared/components/styled/plain-card/PlainCardFooter";
import PlainCardHeader from "../../../shared/components/styled/plain-card/PlainCardHeader";
import UploadImage from "../../../shared/components/upload-image/UploadImage";
import { CultureStatus } from "../../../shared/enums/CultureStatus.enum";
import { GrowthProgress } from "../../../shared/enums/GrowthProgress.enum";
import { PotShape } from "../../../shared/enums/PotShape.enum";
import { PublishingStatus } from "../../../shared/enums/PublishingStatus.enum";
import { Themes } from "../../../shared/enums/Themes.enum";
import { Culture } from "../../../shared/interfaces/Culture.interface";
import { PlantType } from "../../../shared/interfaces/PlantType.interface";
import { PlantVariety } from "../../../shared/interfaces/PlantVariety.interface";
import { Pot as PotInterface } from "../../../shared/interfaces/Pot.interface";
import { PotSlot } from "../../../shared/interfaces/PotSlot.interface";
import { PotType } from "../../../shared/interfaces/PotType.interface";
import { User } from "../../../shared/interfaces/User.interface";
import { saveCulture } from "../../../shared/services/cultures/CulturesService";
import AddVariety from "./components/add-variety/AddVariety";
import PickPotType from "./components/edit-culture-part/components/pick-pot-type/PickPotType";
import EditCulturePart from "./components/edit-culture-part/EditCulturePart";

interface AddCultureProps {
    user:User;
}
 

const AddCulture: FunctionComponent<AddCultureProps> = (props) => {
  const {user} = props;
  const [name, setName] = useState<string>("");
  const [multi, setMulti] = useState<boolean>(false);
  const [place, setPlace] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [plantVarieties, setPlantVarieties] = useState<PlantVariety[]>([]);
  const [showPickVariety, setShowPickVariety] = useState<boolean>(false);
  const [pots, setPots] = useState<PotInterface[]>([]);
  const [showPickPotType, setShowPickPotType] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [imageSource, setImageSource] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [inLand, setInLand] = useState<boolean>(false);
  const [noPots, setNoPots] = useState<number>(1);
  const [standardPot, setStandardPot] = useState<PotType>();
  const [css, theme] = useStyletron();
  const nav = useNavigate();

  useEffect(() => {
    ReduxService.instance.setTheme(Themes.Culture);
  }, []);

  useEffect(() => {
    if(plantVarieties[0] && !name) {
      setName(`${(plantVarieties[0].plantType as PlantType).name} "${plantVarieties[0].name}"`);
    }
  }, [plantVarieties]);

  useEffect(() => {
    const newPots = [...pots];
    newPots.map(pot => {
      const rowKeys = Object.keys(pot.rows);
      const rows = pot.rows;
      rowKeys.forEach(rowKey => {
        const columnKeys = Object.keys(rows[+rowKey]);
        columnKeys.forEach(columnKey => {
          rows[+rowKey][+columnKey][0].changeDate = startDate;
        });
      });
      return {...pot, rows};
    });
    setPots(newPots);
  }, [startDate]);

  useEffect(() => {
    if(noPots && (inLand || standardPot)) { 
      handleAddPots();
    }
  }, [noPots, standardPot, inLand]);
  
  const getPotRows = (pot:PotInterface): GrowthProgress[][] => {
    const potRows = Object.values(pot.rows).map(row => {
      return Object.values(row).map(col => col[0].progress);
    });
    return potRows;
  };

  const handleAddPots = () => {
    const newPots: PotInterface[] = [];
    if(inLand) {
      for (let index = 0; index < noPots; index++) {
        newPots.push(createLand());
      }
    }
    if(!inLand && standardPot) {
      for (let index = 0; index < noPots; index++) {
        newPots.push(createPot(standardPot));
      }
    }
    setPots(newPots);
  };

  const potHasErrors = (pot: PotInterface): boolean => {
    let invalid = false;
    if(multi) {

      Object.values(pot.rows).forEach(row => {
        Object.values(row).forEach(column => {
          if(!column[0].plantVariety) {
            invalid = true;
          }
          if(column[0].noSeeds < 1) {
            invalid = true;
          }
        });
      });
      
    }

    return invalid;
  };


  const createLand = (): PotInterface => {
    const landType: PotType = {
      shape: PotShape.Bed,
      id: uuid(),
      owner: user.id,
      multi:false,
      slotSize:0,
      height:0,
    };

    return {
      id:  uuid(),
      potType: landType,
      multiVariety: false,
      rows:{0:{0:[{noSeeds:1, progress: GrowthProgress.Seed, status:CultureStatus.Active, changeDate:startDate}]}},
      status:PublishingStatus.Published
    };
  };



  const createPot = (potType: PotType): PotInterface => {
    let rows:{[key:string]: {[key:string]: PotSlot[]}} = {0:{0:[{noSeeds:1, progress: GrowthProgress.Seed, status:CultureStatus.Active, changeDate:startDate}]}};
    
    if(potType.multi && potType.slotsOrder) {
      rows = potType.slotsOrder.reduce((map, row, index) => {

        let columns: {[id:string]: PotSlot[]} = {};

        for (let i = 0; i < row; i++) {
          columns = {...columns, [i]:[{noSeeds:1, progress: GrowthProgress.Seed, status:CultureStatus.Active, changeDate:startDate}]
          };
        }
        map = {...map, [index]:columns};
        return map;
      }, {});

    
    }
    return {
      id: uuid(),
      multiVariety: false,
      potType: potType,
      rows,
      status:PublishingStatus.Published
    };
  };

  

  const updateVarieties = () => {
    if(multi) {
      const multiVariety = pots.reduce((varieties: PlantVariety[], pot) => {
        const potVarieties: PlantVariety[] = varieties;
        Object.values(pot.rows).forEach(row => {
          Object.values(row).forEach(column => {
            if(column[0].plantVariety) {

              if(!potVarieties.length  ) {
                potVarieties.push(column[0].plantVariety as PlantVariety);
              } else if(!potVarieties.map(variety => variety.id).includes((column[0].plantVariety as PlantVariety).id) ) {
                potVarieties.push(column[0].plantVariety as PlantVariety);
              }
            }
          });
        });
        return potVarieties;
      }, []);
      setPlantVarieties(multiVariety);
    } 
   
    setCurrentStep(currentStep+1);
  };


  const handleSetStandardPot = (pot: PotType) => {
    setStandardPot(pot);
    setShowPickPotType(false);
  };

  const deletePot = (index:number) => {
    if(noPots > 1) {

      const allPots = [...pots];
      if(index === pots.length -1) {
        setCurrentStep(currentStep-1);
      }
      allPots.splice(index, 1);
      setPots([...allPots]);
      setNoPots(noPots-1);
    }
  };

  const updatePot = (pot:PotInterface, index: number) => {
    const newPots = [...pots];
    newPots[index] = pot;
    setPots(newPots);
  };

  const submit = () => {
   
    const data: Partial<Culture> = {
      pots, 
      multi,
      place:[{place, changeDate:startDate}],
      comments:[{comment, date:startDate}],
      name,
      plantVarieties,
      imageSource
    };
    
    saveCulture(data).then(result => {
      console.log(result);
      nav("/culture");
    });
  };

  return (  
    <ThemeProvider theme={CultureTheme}>
     
      <Grid>
        <Cell span={[12]}>
          <ProgressSteps
            current={currentStep}
            overrides={{Root:{style:{width:"100%"}}}}
          >
            <Step title="Datum">
              <ContentWrapper $noPad>
                <PlainCard>
                  <FormControl label="Startdatum">
                    <DatePicker
                      value={startDate}
                      onChange={({date}) => setStartDate(date as Date)}
                      formatString="yyyy-MM-dd"
                      mask="9999-99-99"
                    />
                  </FormControl>
                </PlainCard>
              </ContentWrapper>
              <Btn color="accent" disabled={!startDate} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep+1)}>Nästa</Btn>
            </Step>
            <Step title="Sort">  
              <ContentWrapper $noPad>
                <PlainCard>
                  {!multi ? (
                    <>
                      <FormControl>
                        <ContentWrapper $noPad>
                          <p>{plantVarieties[0] ? `${(plantVarieties[0].plantType as PlantType).name} "${plantVarieties[0].name}"` : "Ingen sort vald"}</p>
                        </ContentWrapper>
                      </FormControl>

                      <PlainCardFooter >
                        <Btn color="accent" size={SIZE.compact} onClick={() => setShowPickVariety(true)}>Välj sort</Btn>
                        <AddVariety isOpen={showPickVariety} onClose={() => setShowPickVariety(false)} onPickVariety={(variety) => setPlantVarieties([variety])}></AddVariety>
                      </PlainCardFooter>
                    </>) : (
                    <ContentWrapper>
                        Sort väljs i varje kruka/land
                    </ContentWrapper>
                  )}
                    
                </PlainCard>
              </ContentWrapper>
             
              <ContentWrapper $noPad>
                <FormControl>
                  <Checkbox  checked={multi} checkmarkType={STYLE_TYPE.toggle_round}   onChange={(e:any) => setMulti(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.right}>
                        Odlingen består av flera sorter.
                  </Checkbox>
                </FormControl>
              </ContentWrapper>
            
              <Btn color="accent" kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep-1)}>Föregående</Btn>
              <Btn color="accent" disabled={!multi && plantVarieties.length < 1} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep+1)}>Nästa</Btn>
            
            </Step>
                    
            <Step title="Krukor/land">
              {!inLand && (
                <ContentWrapper $noPad>
                  <PlainCard>
                  
                
                    <FormControl>
                      
                      {standardPot ? (
                        <ContentWrapper $noPad $noMargin style={{marginTop:"-30px"}}>
                          <Pot potType={standardPot}/>
                        </ContentWrapper>
                      ): (<ContentWrapper $noPad $noMargin>"Välj en kruka att ha som standardkruka för odlingen"</ContentWrapper>)}
                      
                    </FormControl>

                    <PlainCardFooter>
                      <Btn color="accent" size={SIZE.compact} onClick={() => setShowPickPotType(true)}>Välj kruka</Btn>
                      <PickPotType isOpen={showPickPotType} onClose={() => setShowPickPotType(false)} onPickPotType={(potType) => handleSetStandardPot(potType)}></PickPotType>  
                    </PlainCardFooter>

                  </PlainCard>
                </ContentWrapper>
              )}
              <ContentWrapper $noPad>
                <PlainCard>
                  <ContentWrapper $noPad>
                    <FormControl>
                      <Checkbox  checked={inLand} checkmarkType={STYLE_TYPE.toggle_round}   onChange={(e:any) => setInLand(e.target.checked)}
                        labelPlacement={LABEL_PLACEMENT.right}>
                        Direktsådd i land.
                      </Checkbox>
                    </FormControl>
                  </ContentWrapper>

                  <ContentWrapper $noPad>
                    <FormControl label={`Antal ${inLand ? "land" : "krukor"}`}>
                      <Input value={noPots} onChange={(e:any) => setNoPots(e.target.value)} type="numer"/>
                    </FormControl>
                  </ContentWrapper>
                </PlainCard>
              </ContentWrapper>

              <Btn color="accent" kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep-1)}>Föregående</Btn>
              <Btn color="accent" disabled={pots.length < 1 || noPots < 1} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep+1)}>Nästa</Btn>
           
            </Step>

            {pots.map((pot, index)=> (
              <Step key={pot.id} title={`${inLand ? "Land" : "Kruka"} ${index+1}`}>
                <ContentWrapper $noPad>
                
                  <EditCulturePart pot={pot} index={index} multiVariety={multi}  onValueChange={(pot) => updatePot(pot, index)}></EditCulturePart>
            
                </ContentWrapper>
                <Btn color="accent" kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep-1)}>Föregående</Btn>
                {noPots > 1 && (<Btn color="danger" shape={SHAPE.pill} size={SIZE.compact} onClick={() => deletePot(index)}>Ta bort {inLand ? "land" : "kruka"}</Btn>)}
                <Btn color="accent" disabled={potHasErrors(pot)} shape={SHAPE.pill} size={SIZE.compact} onClick={updateVarieties}>Nästa</Btn>
              </Step>
            ))}

            <Step title="Grunduppgifter">
              <Grid>

                <Cell span={[4,4,6]}>
                  <ContentWrapper $noPad>
                    <PlainCard>
                      <ContentWrapper $noPad>
                        <FormControl label="Namn"> 
                          <Input value={name} onChange={(e: any) => setName(e.target.value)}/>
                        </FormControl>
                      </ContentWrapper>
                    </PlainCard>
                  </ContentWrapper>
                </Cell>
                <Cell span={[4,4,6]}>

                  <ContentWrapper $noPad>
                    <PlainCard>
                      <ContentWrapper $noPad>
                        <FormControl label="Placering">
                          <>
                            <Input value={place} onChange={(e: any) => setPlace(e.target.value)}/>
                            <small>T.ex. "Växthuset", "Grönsakslandet" eller "Hylla 4B"</small>
                          </>
                        </FormControl>
                      </ContentWrapper>
                    </PlainCard>
                  </ContentWrapper>
                </Cell>
                <Cell span={[12]}>

                  <ContentWrapper $noPad>
                    <PlainCard>
                      <UploadImage imageUrl={imageSource} onSuccess={(url) => setImageSource(url)}/>
                    </PlainCard>
                  </ContentWrapper>
                </Cell>
              </Grid>
              <Btn color="accent" kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep-1)}>Föregående</Btn>
              <Btn color="accent" shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep+1)}>Nästa</Btn>
            </Step>
 
            <Step title="Sammanställning">
              {plantVarieties.length > 0 && (
                <>


                  <ContentWrapper $noPad>

                    <PlainCard>
                      <PlainCardHeader>
                        <h4>{name}</h4>
                      </PlainCardHeader>
                      <ContentWrapper $noPad>
                        <List>
                          <ListItem sublist artwork={RiSeedlingFill}>
                            <ListItemLabel sublist>
                              {(plantVarieties as PlantVariety[])?.map((variety) => variety?.name).join(", ")}
                            </ListItemLabel>
                          </ListItem>
                          <ListItem sublist artwork={GiGreenhouse}>
                            <ListItemLabel sublist>
                              {place}
                            </ListItemLabel>
                          </ListItem>
                          <ListItem sublist artwork={RiCalendar2Fill}>
                            <ListItemLabel sublist>
                              {startDate.toLocaleDateString()}
                            </ListItemLabel>
                          </ListItem>
                        </List>
                        <ContentWrapper $flex $noPad $noMargin>
                          {pots.map(pot => ( 
                            <div key={pot.id} className={css({transform:"scale(0.75)"})}>
                              <Pot potType={pot.potType} potRows={getPotRows(pot)}></Pot>
                            </div>
                          ))}    
                        </ContentWrapper>
                      </ContentWrapper>
                    </PlainCard>
                  </ContentWrapper>
                  <ContentWrapper $noPad>
                    <PlainCard>
                      <FormControl label="Kommentar">
                        <ContentWrapper $noPad>
                          <Textarea value={comment} onChange={(e:any) => setComment(e.target.value)}></Textarea>
                        </ContentWrapper>
                      </FormControl>
             
                    </PlainCard>
                  </ContentWrapper>
                  <Btn color="accent" kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={() => setCurrentStep(currentStep-1)}>Föregående</Btn>
                  <Btn color="success" shape={SHAPE.pill} size={SIZE.compact}  onClick={submit}>Spara</Btn>
                </>
              )}
            </Step>
          </ProgressSteps>
        </Cell>
        
        
        
      </Grid>
    </ThemeProvider>
  );
};
 
const mapStateToProps = (state:any) => {
  return {
    user:state.user.data
  };
};
export default connect(mapStateToProps)(AddCulture);