import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Cell, Grid } from "baseui/layout-grid";
import { Textarea } from "baseui/textarea";
import { HeadingMedium } from "baseui/typography";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReduxService } from "../../../core/services/Redux";
import Btn from "../../../shared/components/styled/Btn";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import PlainCard from "../../../shared/components/styled/plain-card/PlainCard";
import { Themes } from "../../../shared/enums/Themes.enum";
import {
  getPlantVariety,
  savePlantVariety,
  updatePlantVariety,
} from "../../../shared/services/plant-variety/PlantVarietyService";
import SetPlantVarietyType from "./components/set-plant-variety-type/SetPlantVarietyType";

const EditPlantVariety: FunctionComponent = () => {
  const { varietyId } = useParams();
  const plantVariety = getPlantVariety(varietyId || "");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [plantTypeId, setPlantTypeId] = useState<string>("");
  const nav = useNavigate();

  useEffect(() => {
    ReduxService.instance.setTheme(Themes.Plant);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (varietyId === "new") {
        console.log("NEW");
      } else if (varietyId && plantVariety) {
        setName(plantVariety.name);
        if (plantVariety.description) {
          setDescription(plantVariety.description);
        }
        if (plantVariety.plantType) {
          setPlantTypeId(plantVariety.plantType.id);
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [varietyId, plantVariety]);

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };
  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleSubmit = () => {
    const data = { name: name, description: description };
    if (varietyId && varietyId !== "new") {
      updatePlantVariety(varietyId, data, plantTypeId);
      nav("/plants/list");
    }
    if (!varietyId || varietyId === "new") {
      savePlantVariety(data, plantTypeId).then((result) => {
        nav(`/plants/variety/${result.id}`);
      });
    }
  };

  return (
    <Grid>
      <Cell span={[12]}>
        <header>
          <HeadingMedium>
            {varietyId === "new" ? "Ny sort" : `Redigerar ${name}`}
          </HeadingMedium>
        </header>
      </Cell>
      <Cell span={[6]}>
        <ContentWrapper $noPad>
          <PlainCard>
            <FormControl label="Namn">
              <Input
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={handleNameChange}
              ></Input>
            </FormControl>
            <FormControl label="Beskrivning">
              <Textarea
                id="description"
                name="description"
                type="text"
                value={description}
                onChange={handleDescriptionChange}
              ></Textarea>
            </FormControl>
          </PlainCard>
        </ContentWrapper>
      </Cell>
      <Cell span={[6]}>
        <ContentWrapper $noPad>
          <SetPlantVarietyType
            typeId={plantTypeId}
            setTypeId={(id) => setPlantTypeId(id)}
          />
        </ContentWrapper>
      </Cell>
      <Cell span={[12]}>
        <Btn
          color="success"
          disabled={!name || !plantTypeId}
          onClick={handleSubmit}
        >
          SPARA
        </Btn>
      </Cell>
    </Grid>
  );
};

export default EditPlantVariety;
