import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp } from "firebase/firestore";
import { ReduxService } from "../../core/services/Redux";
import { PlantType } from "../interfaces/PlantType.interface";

class PlantTypeConverter implements FirestoreDataConverter<PlantType> {
  toFirestore(event: PartialWithFieldValue<PlantType>): DocumentData {
    const user = ReduxService.instance.getUser();
    return {
      ...event,
      createdBy: event.createdBy || user.id, 
      createdAt: event.createdAt || serverTimestamp(),
      updatedBy: user.id, 
      updatedAt: serverTimestamp()
    };
  }

  fromFirestore(snap: QueryDocumentSnapshot): PlantType {
    const data = snap.data();
    const id = snap.id;
    return {...data, id} as PlantType;
  }
}

export default PlantTypeConverter;