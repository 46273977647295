import { useStyletron } from "baseui";
import { HeadingMedium } from "baseui/typography";
import { FunctionComponent, useEffect } from "react";
import { ReduxService } from "../../../core/services/Redux";
import ListCultures from "../../../shared/components/list-cultures/ListCultures";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import { Themes } from "../../../shared/enums/Themes.enum";

const MyCultures: FunctionComponent = () => {
  const [css, theme] = useStyletron();

  useEffect(() => {
    ReduxService.instance.setTheme(Themes.Culture);
  }, []);

  return (
    <>
      <ContentWrapper $noPad>
        <header>
          <HeadingMedium>Mina odlingar</HeadingMedium>
        </header>
        <ListCultures backgroundColor={theme.colors.white} startOpen />
      </ContentWrapper>
    </>
  );
};

export default MyCultures;
