import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp } from "firebase/firestore";
import { User } from "../interfaces/User.interface";

class UserConverter implements FirestoreDataConverter<User> {
  toFirestore(event: PartialWithFieldValue<User>): DocumentData {
    return {
      ...event,
      createdAt: event.createdAt || serverTimestamp(),
      updatedAt: serverTimestamp()
    };
  }
  fromFirestore(snap: QueryDocumentSnapshot): User {
  
    return snap.data() as User;
  }
}

export default UserConverter;