import { Check } from "baseui/icon";
import { ListItem, ListItemLabel } from "baseui/list";
import { FunctionComponent } from "react";
import List from "../../../styled/List";
import { PlantSubCategory } from "../../../../interfaces/PlantSubCategory.interface";

interface ListSubCategoriesProps {
    subCategories: PlantSubCategory[]
    selected: string;
    setSelected: (id:string) => void;
}
 
const ListSubCategories: FunctionComponent<ListSubCategoriesProps> = (props) => {
  const {subCategories, selected, setSelected} = props;


  return  subCategories.length < 1 ? (<div>Inga underkategorier</div>) : (
    <List $noPad>
      <ListItem   
        endEnhancer={() => (
          selected === subCategories[0].parent.id && <Check /> 
        )}>
        <div onClick={() => setSelected(subCategories[0].parent.id)} style={{cursor:"pointer"}}>
          <ListItemLabel>Alla</ListItemLabel>
        </div>
         
      </ListItem>
      {subCategories.map((cat:PlantSubCategory) => (
        <ListItem  key={cat.id} 
          endEnhancer={() => (
            selected === cat.id && <Check /> 
          )}>
          <div onClick={() => setSelected(cat.id)} style={{cursor:"pointer"}}>
            <ListItemLabel>{cat.name}</ListItemLabel>
          </div>
         
        </ListItem>
      ))}
    </List>
  );
};
 
export default ListSubCategories;