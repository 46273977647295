import { Accordion, Panel } from "baseui/accordion";
import {  KIND, SHAPE, SIZE } from "baseui/button";
import { Cell, Grid } from "baseui/layout-grid";
import { ListItem, ListItemLabel } from "baseui/list";
import { HeadingSmall } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { GiCardPickup } from "react-icons/gi";
import { RiEdit2Fill } from "react-icons/ri";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PlantType } from "../../interfaces/PlantType.interface";
import { PlantVariety } from "../../interfaces/PlantVariety.interface";
import { User } from "../../interfaces/User.interface";
import { getPlantCategories } from "../../services/plant-categories/PlantCategoriesService";
import { getPlantTypesPopulated } from "../../services/plant-type/PlantTypeService";
import { getPlantVarieties } from "../../services/plant-variety/PlantVarietyService";
import Btn from "../styled/Btn";
import ContentWrapper from "../styled/ContentWrapper";
import List from "../styled/List";
import PlainCard from "../styled/plain-card/PlainCard";
import PlainCardFooter from "../styled/plain-card/PlainCardFooter";
import ListSubCategories from "./components/list-sub-categories/ListSubCategories";
import "./ListPlants.css";


interface ListPlantsProps {
    onPickType?: (id:string) => void;
    onPickVariety?: (variety: PlantVariety) => void;
    $disableEdit?: boolean;
    $hideVariety?: boolean;
    cardColor?: string;
    user: User;
}

const ListPlants = (props: ListPlantsProps) => {
  const {onPickType, onPickVariety, $disableEdit, $hideVariety, cardColor, user} = props;
  const allVarieties: PlantVariety[] = getPlantVarieties();
  const plantCategories = getPlantCategories();
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const allPlantTypes: PlantType[] = getPlantTypesPopulated();
  const [plantTypes, setPlantTypes] = useState<PlantType[]>([]);
  const [plantVarieties, setPlantVarieties] = useState<PlantVariety[]>([]);

  const nav = useNavigate();

 

  const updateVarieties = () => {
    if(selectedType && plantTypes) {
      const vars: PlantVariety[] = allVarieties.filter(variety => variety?.plantType?.id === selectedType) as PlantVariety[];
     
      setPlantVarieties(vars);
  
    }
    if(!selectedType &&  plantTypes && selectedSubCategory) {
      const typeIds = plantTypes.map(type => type.id);
      const vars: PlantVariety[] = allVarieties.filter(variety => typeIds.includes(variety.plantType.id)) as PlantVariety[];
      setPlantVarieties(vars);
    } else if(allVarieties && !selectedType && !selectedType) {
      setPlantVarieties(allVarieties);
    }
  };

  const updatePlantTypes = ()  => {
    if(selectedSubCategory && allPlantTypes) {
      const types = allPlantTypes.filter(type => type.categories?.map(cat => cat.id).includes(selectedSubCategory));
      setPlantTypes(types);
    } else if (!selectedSubCategory && allPlantTypes) {
      setPlantTypes(allPlantTypes);
    }
  };



  const getPlantTypeName = (id: string | undefined): string => {
    if(id) {
      const plantType = allPlantTypes.find(type => type.id === id);
      return plantType ? plantType.name : "";
    }
    return "";
  };

  useEffect(() => {
    let mounted = true;
    if(mounted) {
      updatePlantTypes();
    }
    return() => {mounted = false;};

  },[selectedSubCategory, allPlantTypes]);

  useEffect(() => {
    let mounted = true;
    if(mounted) {
      updateVarieties();
    }
    return() => {mounted = false;};

  },[plantTypes, selectedType, allPlantTypes]);


  const handleEditCategory = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, id:string) => {
    event.preventDefault();
    nav(`/plants/category/${id}`);
  };

  const handleEditType = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, id:string) => {
    event.preventDefault();
    nav(`/plants/type/${id}`);
  };

  const handleEditVariety = (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, id:string) => {
    event.preventDefault();
    nav(`/plants/variety/${id}`);
  };


  const handleSelectedSubCategory = (id: string, expanded: boolean):void => {
    if(expanded) {
      setSelectedSubCategory(id);
    }
   
    if(!expanded) {
      setSelectedSubCategory("");
    }
  };

  const handlePickType = (id:string) => {
    if(onPickType) {
      onPickType(id);
    } else {
      if(selectedType !== id) {

        setSelectedType(id);
      } else {
        setSelectedType("");
      }
    }
  };

  const getFullPlantVariety = (variety: PlantVariety): PlantVariety => {
    const fullVariety = variety;
    fullVariety.plantType = plantTypes.find(type => type.id === variety.plantType.id) as PlantType;
    return fullVariety;
  };

  return  (
    <Grid>
      <Cell span={[4, 4, 4]}>
        <ContentWrapper $noPad>
          <PlainCard $backgroundColor={cardColor || ""}>
            <HeadingSmall>Kategorier</HeadingSmall>
            <ContentWrapper $noPad>

              <Accordion accordion>
                {plantCategories?.map(cat => (
                  <Panel onChange={({expanded}) => handleSelectedSubCategory(cat.id, expanded)} key={cat.id} title={<div> {$disableEdit || cat.createdBy !== user.id ? "" : <Btn onClick={(e) => handleEditCategory(e, cat.id)} shape={SHAPE.circle} size={SIZE.compact} kind={KIND.secondary}><RiEdit2Fill></RiEdit2Fill></Btn>} <span>{cat.name}</span></div>}>
               
                    {cat.subCategories && (
                      <ListSubCategories 
                        selected={selectedSubCategory} 
                        setSelected={(id) => setSelectedSubCategory(id)} subCategories={cat.subCategories}
                      >
                      </ListSubCategories>
                    )}
                  </Panel>
                ))}
              </Accordion>
            </ContentWrapper>
            <PlainCardFooter>
              {!$disableEdit && (<Btn  size={SIZE.compact}  onClick={() => nav("/plants/category/new")}>Lägg till...</Btn>)}
            </PlainCardFooter>
       
          </PlainCard>
        </ContentWrapper>
      </Cell>
      <Cell span={[4, 4, 4]}>

        <ContentWrapper $noPad>
          <PlainCard  $backgroundColor={cardColor || ""}>

            <HeadingSmall>Växter</HeadingSmall>
            {plantTypes && (
              <List>
                {plantTypes.map(plantType => (
                  <ListItem key={plantType.id} endEnhancer={() => 
                    (!$disableEdit && plantType.createdBy === user.id ? (
                      <Btn onClick={(e) => handleEditType(e, plantType.id)} size={SIZE.compact} shape={SHAPE.circle} kind={KIND.secondary}><RiEdit2Fill></RiEdit2Fill></Btn>
                    ) : onPickType ? (  
                      <Btn onClick={() => handlePickType(plantType.id)}  
                        color="success"
                        size={SIZE.compact} 
                        shape={SHAPE.circle} 
                        kind={KIND.primary}
                      >
                        <GiCardPickup/>
                      </Btn>): "")}>
                    <div className={"clickable"}>
                      <ListItemLabel>{plantType.name}</ListItemLabel>
                    </div>
                  </ListItem>
                ))}
              </List>
            )}
            <PlainCardFooter>
              {!$disableEdit && (<Btn  size={SIZE.compact}  onClick={() => nav("/plants/type/new")}>Lägg till...</Btn>)}
            </PlainCardFooter>
          </PlainCard>
        </ContentWrapper>
        

      </Cell>

      {!$hideVariety && (
        <Cell span={[4, 8, 4]}>

          <ContentWrapper $noPad>
            <PlainCard  $backgroundColor={cardColor || ""}>     
              <HeadingSmall>Sorter</HeadingSmall>
              {plantVarieties && (
                <List>
                  {plantVarieties.map(plantVariety => (
                    <ListItem 
                      key={plantVariety.id} 
                      endEnhancer={() => 
                        (!$disableEdit && plantVariety.createdBy === user.id ? (
                          <Btn onClick={(e) => handleEditVariety(e, plantVariety.id)}  
                            size={SIZE.compact} 
                            shape={SHAPE.circle} 
                            kind={KIND.secondary}
                          >
                            <RiEdit2Fill/>
                          </Btn>) : onPickVariety ? (
                          <Btn color="success" onClick={(e) => onPickVariety(getFullPlantVariety(plantVariety))}  
                            size={SIZE.compact} 
                            shape={SHAPE.circle} 
                            kind={KIND.primary}
                          >
                            <GiCardPickup/>
                          </Btn>
                        ): "")}
                    >
                      <ListItemLabel>{getPlantTypeName(plantVariety.plantType.id)} "{plantVariety.name}"</ListItemLabel>
                    </ListItem>
                  ))}
                </List>
              )}
              <PlainCardFooter>
                {!$disableEdit && ( <Btn size={SIZE.compact} onClick={() => nav("/plants/variety/new")}>Lägg till...</Btn>)}
              </PlainCardFooter>
            </PlainCard>
          </ContentWrapper>
        </Cell>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(ListPlants);