import { addDoc, collection, doc, DocumentReference, getDoc, getFirestore, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { app } from "../../../core/services/Firebase";
import PlantCategoryConverter from "../../converters/PlantCategoryConverter";
import PlantSubCategoryConverter from "../../converters/PlantSubCategoryConverter";
import PlantTypeConverter from "../../converters/PlantTypeConverter";
import { PlantType } from "../../interfaces/PlantType.interface";
import { getPlantCategories } from "../plant-categories/PlantCategoriesService";
import { getPlantVarieties } from "../plant-variety/PlantVarietyService";

const db =  getFirestore(app);
const converter = new PlantTypeConverter();
const catConverter = new PlantCategoryConverter();
const subCatConverter = new PlantSubCategoryConverter();


export function getPlantType(id:string): PlantType {
  const [type, setType] = useState<PlantType>({name:"", id:"", categories:[]});
  const [query, setQuery] = useState<DocumentReference<PlantType>>();
  const cats = getPlantCategories();
 
  useEffect(() => {
    if(id) {
      setQuery(doc(db, "plantType", id).withConverter(converter));
    }
  }, [id]);
  
  useEffect(() => {
    if(query) {
      onSnapshot(query, (doc) => {
        const data = doc.data();
        if(data) {
          if(cats) {
            let newCats = cats.filter(cat => data.categories?.map(cat => cat.id).includes(cat.id));
            newCats = newCats.map(cat => {
              const newSubcats = cat.subCategories?.filter(subcat => data.categories?.map(cat => cat.id).includes(subcat.id));
              return {...cat, subCategories: newSubcats};
            });

            data.categories = newCats;
          }
          setType(data);
        }
      }); 
    }
  }, [query, cats]);

  return type;
}

export function getPlantTypes(catIds?: string[], subCatIds?: string[]): PlantType[] {
  const [types, setTypes] = useState<PlantType[]>([]);
    
  const col = collection(db, "plantType").withConverter(converter);
    
  useEffect(() => {
    const cats: DocumentReference<any>[] = [];
    if(catIds && catIds.length > 0) {
      catIds.forEach(id => {
        if(id) {

          const catdoc = doc(db, "plantCategory", id).withConverter(catConverter);
          cats.push(catdoc);
        }
      });
    }
    if(subCatIds && subCatIds.length > 0) {
      subCatIds.forEach(id => {
        if(id) {
          const subCatDoc = doc(db, "plantSubCategory", id).withConverter(subCatConverter);
          cats.push(subCatDoc);
        }
      });
    }
    onSnapshot(cats.length > 0 ? query(col,  where("categories", "array-contains-any", cats)) : col, (querySnap) => {
      const data = querySnap.docs.map(doc => doc.data());
      
      setTypes(data);
    });
             
  }, [subCatIds, catIds]);
    
  return types;
}

export function getPlantTypesPopulated(catIds?: string[], subCatIds?: string[]): PlantType[] {
  const [types, setTypes] = useState<PlantType[]>([]);
  const varieties = getPlantVarieties();
    
  const col = collection(db, "plantType").withConverter(converter);
    
  useEffect(() => {
    const cats: DocumentReference<any>[] = [];
    if(catIds && catIds.length > 0) {
      catIds.forEach(id => {
        if(id) {

          const catdoc = doc(db, "plantCategory", id).withConverter(catConverter);
          cats.push(catdoc);
        }
      });
    }
    if(subCatIds && subCatIds.length > 0) {
      subCatIds.forEach(id => {
        if(id) {
          const subCatDoc = doc(db, "plantSubCategory", id).withConverter(subCatConverter);
          cats.push(subCatDoc);
        }
      });
    }
    onSnapshot(cats.length > 0 ? query(col,  where("categories", "array-contains-any", cats)) : col, (querySnap) => {
      let data = querySnap.docs.map(doc => doc.data());
      if(varieties) {
        const newData = data.map(type => {
        
          const newVarieties = varieties.filter(variety => variety.plantType.id === type.id);
         
          return {...type, varieties: newVarieties};
        });
        data = newData;
      }
      setTypes(data);
      console.log("UPDATED CATS");
    });
             
  }, [subCatIds, catIds]);
    
  return types;
}

export const savePlantType = async (data: Partial<PlantType>, categoryId:string, subCategoryId: string,): Promise<PlantType>  => {

  const query = collection(db, "plantType").withConverter(converter);
  const category = doc(db, "plantCategory", categoryId).withConverter(catConverter);
  const subCategory = doc(db, "plantSubCategory", subCategoryId).withConverter(subCatConverter);
  data.categories = [category, subCategory];

  let docRef;
  try {
    docRef = await addDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }
  let snapshot;
  try {
    snapshot = await getDoc(docRef.withConverter(converter));
  } catch (error) {
    return Promise.reject(error);
  }
  const result = snapshot.data();
  if(result) {
    return Promise.resolve(result);
  } else {
    return Promise.reject("No plant type returned");
  }
};

export const updatePlantType = async (id:string, data: Partial<PlantType>, categoryId?:string, subCategoryId?: string,): Promise<boolean> =>{

  const query = doc(db, "plantType", id).withConverter(converter);

  const categories = [];
  if(categoryId) {
    const category = doc(db, "plantCategory", categoryId).withConverter(catConverter);
    categories.push(category);
  }
  if(subCategoryId) {
    const subCategory = doc(db, "plantSubCategory", subCategoryId).withConverter(subCatConverter);
    categories.push(subCategory);
  }
  data.categories = categories;

  try {
    await setDoc(query, data, {merge:true});
  } catch (error) {
    return Promise.reject(error);
  }
  
  return Promise.resolve(true);
  
};


