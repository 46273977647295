import { useStyletron } from "baseui";
import { Card, CardProps } from "baseui/card";
import { FunctionComponent } from "react";

interface PlainCardProps extends CardProps {
    $backgroundColor?: string;
}

 
const PlainCard : FunctionComponent<PlainCardProps> = (props) => {
  const { $backgroundColor } = props;
  const [css, theme] = useStyletron();
  return (  <Card 
    overrides={{
      Root:{
        style:{ 
          borderTopWidth:0, 
          borderBottomWidth:0, 
          borderLeftWidth:0, 
          borderRightWidth:0, 
          overflow:"visible",
          position: "relative",
          boxSizing: "border-box",
          boxShadow: theme.lighting.shadow400, width:"100%",
          backgroundColor: $backgroundColor || theme.colors.white,
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,

        }
      }
    }} 
    {...props}>{props.children}
  </Card> );
};
 
export default PlainCard;