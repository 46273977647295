import { Theme, ThemeProvider } from "baseui";
import { signInWithPopup } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import TopBar from "./components/top-bar/TopBar";
import AuthContext from "./core/context/AuthContext";
import { auth, providers } from "./core/services/Firebase";
import { ReduxService } from "./core/services/Redux";
import { CultureTheme } from "./core/theme/CultureTheme";
import { MainTheme } from "./core/theme/MainTheme";
import { PlantTheme } from "./core/theme/PlantTheme";
import { UserTheme } from "./core/theme/UserTheme";
import ContentWrapper from "./shared/components/styled/ContentWrapper";
import { Themes } from "./shared/enums/Themes.enum";
import { User } from "./shared/interfaces/User.interface";
import { syncUser } from "./shared/services/user/UserService";
import AddCulture from "./views/culture/add-culture/AddCulture";
import MyCultures from "./views/culture/my-cultures/MyCultures";
import EditPlantCategory from "./views/plant/edit-plant-category/EditPlantCategory";
import EditPlantType from "./views/plant/edit-plant-type/EditPlantType";
import EditPlantVariety from "./views/plant/edit-plant-variety/EditPlantVariety";
import FindPlants from "./views/plant/find-plants/FindPlants";
import Start from "./views/start/Start";
import EditUser from "./views/user/edit-user/EditUser";
import MyPage from "./views/user/my-page/MyPage";

interface AppProps {
  theme: Themes;
  user: User;
}

const App = (props: AppProps) => {
  const authUser = useContext(AuthContext);
  const { user } = props;
  const [theme, setTheme] = useState<Theme>(MainTheme);
  const signOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, providers.googleProvider);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogOut = () => {
    signOut();
    ReduxService.instance.setUser(null);
  };

  useEffect(() => {
    if (authUser) {
      syncUser(authUser);
    }
  }, [authUser]);

  useEffect(() => {
    switch (props.theme) {
    case Themes.Main:
      setTheme(MainTheme);
      break;
    case Themes.Plant:
      setTheme(PlantTheme);
      break;
    case Themes.User:
      setTheme(UserTheme);
      break;
    case Themes.Culture:
      setTheme(CultureTheme);
      break;
    default:
      setTheme(MainTheme);
    }
  }, [props.theme]);

  return (
    <ThemeProvider theme={theme}>
      <ContentWrapper $noPad $gradientBackground className="wrapper">
        <Router>
          <div className="App">
            {user && <TopBar onLogout={handleLogOut}></TopBar>}

            <ContentWrapper $noPad>
              {user ? (
                <Routes>
                  <Route path="/" element={<MyCultures />} />

                  {/* PLANT ROUTES */}
                  <Route path="/plants/list" element={<FindPlants />} />
                  <Route
                    path="/plants/category/:catId"
                    element={<EditPlantCategory />}
                  />
                  <Route
                    path="/plants/type/:typeId"
                    element={<EditPlantType />}
                  />
                  <Route
                    path="/plants/variety/:varietyId"
                    element={<EditPlantVariety />}
                  />

                  {/* CULTURE ROUTES */}
                  <Route path="/culture" element={<MyCultures />} />
                  <Route path="/culture/new" element={<AddCulture />} />

                  {/* USER ROUTES */}
                  <Route path="/user" element={<MyPage />} />
                  <Route path="/user/edit" element={<EditUser />} />
                </Routes>
              ) : (
                <Start onSignIn={signInWithGoogle}></Start>
              )}
            </ContentWrapper>
          </div>
        </Router>
      </ContentWrapper>
    </ThemeProvider>
  );
};
const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.data,
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(App);
