import { styled, useStyletron } from "baseui";



 
const PlainCardFooterWrapper = styled("div", () => {
  const [css, theme] = useStyletron();
  return { 
   
    boxSizing:"border-box", 
    height:theme.sizing.scale600,
    width:"100%",
  };
});
  
export default PlainCardFooterWrapper;