
import { KIND as ButtonKind } from "baseui/button";
import {
  Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ROLE, SIZE
} from "baseui/modal";
import React, { FunctionComponent } from "react";
import Btn from "../../../../../shared/components/styled/Btn";
import ModalButtonFooter from "../../../../../shared/components/styled/modal/ModalButtonFooter";
import { PlantSubCategory } from "../../../../../shared/interfaces/PlantSubCategory.interface";
import { deletePlantSubCategory } from "../../../../../shared/services/plant-sub-categories/PlantSubCategoriesService";

interface DeleteSubCategoryProps {
    isOpen: boolean
    setIsOpen: (value: null) => void;
    subCat: Partial<PlantSubCategory> | null;
}
 
const DeleteSubCategory: FunctionComponent<DeleteSubCategoryProps> = (props) => {
  const {isOpen, setIsOpen, subCat} = props;

  const onSubmit = () => {
    if(subCat && subCat.id) {
      deletePlantSubCategory(subCat.id).then(() =>  setIsOpen(null));
    }
  };
  
  return subCat && (  <Modal
    onClose={() => setIsOpen(null)}
    closeable
    isOpen={isOpen}
    animate
    autoFocus
    size={SIZE.default}
    role={ROLE.dialog}
  >
    <ModalHeader>Ta bort underkategori</ModalHeader>
    <ModalBody>
        Vill du ta bort underkategorien {subCat.name}?
    </ModalBody>
    <ModalButtonFooter>
      <Btn kind={ButtonKind.secondary} onClick={() => setIsOpen(null)}>
        Nej
      </Btn>
      <Btn color="danger" onClick={onSubmit}>Ja</Btn>
    </ModalButtonFooter>
  </Modal> );
};
 
export default DeleteSubCategory;