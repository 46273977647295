import { useStyletron } from "baseui";
import { Modal, ModalBody, ModalHeader, SIZE } from "baseui/modal";
import { FunctionComponent } from "react";
import ListPlants from "../../../../../shared/components/list-plants/ListPlants";
import { PlantVariety } from "../../../../../shared/interfaces/PlantVariety.interface";



interface AddVarietyProps {
    isOpen:boolean;
    onClose:() => void;
    onPickVariety: (variery: PlantVariety) => void;
}
 
const AddVariety: FunctionComponent<AddVarietyProps> = (props) => {
  const {isOpen, onClose, onPickVariety} = props;
  const [css, theme] = useStyletron();

  const handlePickVariety = (variety: PlantVariety) => {
    onPickVariety(variety);
    onClose();
  };
  return ( 
    <Modal size={SIZE.full} isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
            Välj en sort
      </ModalHeader>
      <ModalBody>
        <ListPlants cardColor={theme.colors.primary50} $disableEdit onPickVariety={handlePickVariety}></ListPlants>
      </ModalBody>
    </Modal>
  );
};
 
export default AddVariety;