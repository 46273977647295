import { User } from "../../shared/interfaces/User.interface";
import { SetUserAction } from "./types";


export enum UserActionTypes {
    SetUser = "setUser"
}

export const setUser = (data: User | null): SetUserAction => ({
  type:UserActionTypes.SetUser,
  data
});