import { addDoc, collection, deleteDoc, doc, getDoc, getFirestore, onSnapshot, PartialWithFieldValue, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import app, { auth } from "../../../core/services/Firebase";
import PotTypesConverter from "../../converters/PotTypesConverter";
import { PotType } from "../../interfaces/PotType.interface";

const db =  getFirestore(app);
const converter = new PotTypesConverter();


export function getPotTypes(): PotType[] {
  const [types, setTypes] = useState<PotType[]>([]);
      
  const col = collection(db, "potTypes").withConverter(converter);
      
  useEffect(() => {
     
    onSnapshot(col, (querySnap) => {
      const data = querySnap.docs.map(doc => doc.data());
        
      setTypes(data);
    });
               
  }, []);
      
  return types;
}

export function getMyPotTypes(): PotType[] {
  const userId = auth.currentUser?.uid;
  const [types, setTypes] = useState<PotType[]>([]);
  if(!userId) {
    return [];
  }

  const col = collection(db, "potTypes").withConverter(converter);
      
  useEffect(() => {
    onSnapshot(query(col, where("owner", "==", userId)), (querySnap) => {
      const data = querySnap.docs.map(doc => doc.data());
      setTypes(data);
    });
               
  }, [userId]);
      
  return types;
}

export async function savePotType(data: PartialWithFieldValue<PotType>): Promise<PotType> {
  const query = collection(db, "potTypes").withConverter(converter);

  let docRef;
  try {
    docRef = await addDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }

  let snapshot;
  try {
    snapshot = await getDoc(docRef.withConverter(converter));
  } catch (error) {
    return Promise.reject(error);
  }
  const result = snapshot.data();
  if(result) {
    return Promise.resolve(result);
  } else {
    return Promise.reject("No plant type returned");
  }
}

export async function deletePotType(id:string): Promise<boolean> {
  const query = doc(db, "potTypes", id).withConverter(converter);
  
  try {
    await deleteDoc(query);
  } catch (error) {
    return Promise.reject(error);
  }

  return Promise.resolve(true);

}