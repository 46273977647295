import { StatefulMenu } from "baseui/menu";
import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "../../../../shared/interfaces/MenuItem.interface";
import { User } from "../../../../shared/interfaces/User.interface";


interface UserMenuProps {
    user: User;
    close: () => void;
    onLogout: () => void;
}
 
const UserMenu: FunctionComponent<UserMenuProps> = (props) => {
  const {close, user, onLogout} = props;
  const nav = useNavigate();
  const items: MenuItem[] = [
    {link:"/user", label:user?.displayName || user.name},
    {link:"/user/edit", label:"Redigera profil"},
    {link:"logout", label:"Logga ut"},
  ];

  const handleMenuChange = (item: MenuItem):void  => {
    if(item.link === "logout") {
      onLogout();
      close();
      nav("/");
    } else {
      close();
      nav(item.link);
    }
  };

  return ( 
    <>
      <StatefulMenu 
        items={items} 
        onItemSelect={({item}) => handleMenuChange(item)}
        
        overrides={{ 
          Option: {
            props: {
              getItemLabel: (item:MenuItem)=> item.label
            }
          }
        }}/>
    
    </>
  );
};
 

const mapStateToProps = (state:any) => {
  return {
    user:state.user.data
  };
};
export default connect(mapStateToProps)(UserMenu);
