
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { FunctionComponent, useState } from "react";
import Btn from "../../../../../../../shared/components/styled/Btn";
import ContentWrapper from "../../../../../../../shared/components/styled/ContentWrapper";
import { GrowthProgress } from "../../../../../../../shared/enums/GrowthProgress.enum";
import { PlantType } from "../../../../../../../shared/interfaces/PlantType.interface";
import { PlantVariety } from "../../../../../../../shared/interfaces/PlantVariety.interface";
import AddVariety from "../../../add-variety/AddVariety";



interface PotVarietyAndProgressProps {
    multiVariety: boolean;
    plantVariety?: PlantVariety
    onPickVariety: (variety: PlantVariety) => void;
    onSetProgress: (progress: GrowthProgress) => void;
    progress: GrowthProgress;
    noSeeds: number;
    onSetNoSeeds: (noSeeds: number) => void;
}
 
const PotVarietyAndProgress: FunctionComponent<PotVarietyAndProgressProps> = (props) => {
  const {multiVariety, plantVariety, onPickVariety, onSetProgress, progress, noSeeds, onSetNoSeeds} = props;
  const [showPickVariety, setShowPickVariety] = useState<boolean>(false);

  const handlePickVariety = (variety: PlantVariety) => {
    onPickVariety(variety);
    setShowPickVariety(false);
  };
  return ( 
    <>
      {multiVariety && (
        <FormControl label="Sort">
          <ContentWrapper $noPad $flex $flexJustify="space-between">
            <>
              <p>{plantVariety ? `${(plantVariety.plantType as PlantType).name} "${plantVariety.name}"` : "Välj en sort..."}</p>
              <Btn color="accent" onClick={() => setShowPickVariety(true)}>Välj sort</Btn>
              <AddVariety isOpen={showPickVariety} onClose={() => setShowPickVariety(false)} onPickVariety={(variety) => handlePickVariety(variety)}></AddVariety>
            </>
          </ContentWrapper>
        </FormControl>
      )}

      <FormControl label="Växtstadie">
        <ContentWrapper $noPad>
          <RadioGroup onChange={(e:any) => onSetProgress(e.currentTarget.value)} value={progress} name="progress" align={ALIGN.horizontal}>
            <Radio value={GrowthProgress.Seed}>Frö</Radio>
            <Radio value={GrowthProgress.Seedling}>Grodd</Radio>
            <Radio value={GrowthProgress.Plant}>Planta</Radio>
          </RadioGroup>
        </ContentWrapper>
      </FormControl>
      <FormControl label={`Antal ${progress}`}>
        <ContentWrapper $noPad>
          <Input type="number" value={noSeeds} onChange={(e:any) => onSetNoSeeds(e.currentTarget.value)}  ></Input>
        </ContentWrapper>
      </FormControl>
    </>
  );
};
 
export default PotVarietyAndProgress;