
export const fontFamilies = {
  primaryFontFamily: "Overpass",
  
};

export const typographyOverrides = {
  ParagraphXSmall: {
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "20px"
  },
  ParagraphSmall: {
    fontSize: "13px",
    fontWeight: "normal",
    lineHeight: "20px"
  },
  ParagraphMedium: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "20px"
  },
  ParagraphLarge: {
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "24px"
  }, 
  HeadingXSmall: {
    fontFamily: "Homemade Apple"
  },
  HeadingSmall: {
    fontFamily: "Homemade Apple"
  },
  HeadingMedium: {
    fontFamily: "Homemade Apple"
  },
  HeadingLarge: {
    fontFamily: "Homemade Apple"
  },
  HeadingXLarge: {
    fontFamily: "Homemade Apple"
  },


  HeadingXXLarge: {
    fontFamily: "Homemade Apple"
  },

  DisplayXSmall: {
    fontFamily: "Homemade Apple"
  },

  DisplaySmall: {
    fontFamily: "Homemade Apple"
  },

  DisplayMedium: {
    fontFamily: "Homemade Apple"
  },

  DisplayLarge: {
    fontFamily: "Homemade Apple"
  }

};