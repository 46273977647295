import { createTheme } from "baseui";
import { replaceColorName } from "../../shared/utils/replaceColorName";
import { colors } from "./Colors";
import { mainOverrides } from "./MainTheme";
import { fontFamilies } from "./Typography";


const userPrimitives =  {
  ...replaceColorName("water", "primary", colors.water),
  ...replaceColorName("terracotta", "accent", colors.terracotta),
  ...replaceColorName("danger", "negative", colors.danger),
  ...replaceColorName("danger", "warning", colors.danger),
  ...replaceColorName("success", "positive", colors.success),
  ...fontFamilies
};


export const UserTheme = createTheme(userPrimitives, mainOverrides);