import { BaseProvider } from "baseui";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import App from "./App";
import { AuthProvider } from "./core/provider/AuthProvider";
import { ReduxService } from "./core/services/Redux";
import { MainTheme } from "./core/theme/MainTheme";
import "./index.css";
import { setupStore } from "./state/store";

const engine = new Styletron();

const store = setupStore();
ReduxService.instance.assignStore(store);

ReactDOM.render(

  <AuthProvider>
    <Provider store={store}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={MainTheme} zIndex={100}>
          <App />
        </BaseProvider>
      </StyletronProvider>
    </Provider>
  </AuthProvider>,
  document.getElementById("root")
);

