import { CardProps } from "baseui/card";
import { FunctionComponent } from "react";
import PlainCardFooterContent from "./PlainCardFooterContent";
import PlainCardFooterWrapper from "./PlainCardFooterWrapper";
interface PlainCardFooterProps extends CardProps {
    $backgroundColor?: string;
}

 
const PlainCardFooter : FunctionComponent<PlainCardFooterProps> = (props) => {
  const { $backgroundColor } = props;
  return ( 
    <PlainCardFooterWrapper >
      <PlainCardFooterContent
        $backgroundColor={$backgroundColor}>{props.children}
      </PlainCardFooterContent>
    </PlainCardFooterWrapper> 
  );
};
 
export default PlainCardFooter;