import { useStyletron } from "baseui";
import { SHAPE, SIZE } from "baseui/button";
import { ListItem, ListItemLabel } from "baseui/list";
import { LabelLarge } from "baseui/typography";
import { FunctionComponent, useState } from "react";
import { GiGreenhouse } from "react-icons/gi";
import { RiArrowDownSFill, RiArrowUpSFill, RiCalendar2Fill, RiSeedlingFill, RiStickyNoteFill } from "react-icons/ri";
import { Pot as PotInterface } from "../../../../../shared/interfaces/Pot.interface";
import { GrowthProgress } from "../../../../enums/GrowthProgress.enum";
import { Culture } from "../../../../interfaces/Culture.interface";
import { PlantVariety } from "../../../../interfaces/PlantVariety.interface";
import Pot from "../../../pot/Pot";
import Btn from "../../../styled/Btn";
import ContentWrapper from "../../../styled/ContentWrapper";
import List from "../../../styled/List";
import PlainCard from "../../../styled/plain-card/PlainCard";
import PlainCardFooter from "../../../styled/plain-card/PlainCardFooter";
import PlainCardHeader from "../../../styled/plain-card/PlainCardHeader";
import DeleteCulture from "../delete-culture/DeleteCulture";


interface CultureContentProps {
    backgroundColor: string;
    culture: Culture;
    hideActions: boolean;
    startOpen: boolean;
}
 
const CultureContent: FunctionComponent<CultureContentProps> = (props) => {
  const {backgroundColor, culture, hideActions, startOpen} = props;
  const [cultureToDelete, setCultureToDelete] = useState<Culture>();
  const [showDeleteCulture, setShowDeleteCulture] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(startOpen);
  const [css] = useStyletron();

    
  const getPotRows = (pot:PotInterface): GrowthProgress[][] => {
    const potRows = Object.values(pot.rows).map(row => {
      return Object.values(row).map(col => col[0].progress);
    });
    return potRows;
  };

  const handleDelete = (culture: Culture) => {
    setCultureToDelete(culture);
    setShowDeleteCulture(true);
  };

  return (  
    <PlainCard $backgroundColor={backgroundColor }>
      <PlainCardHeader>
        {culture.imageSource && (
          <div className={css({width:"80px", height:"80px", borderRadius:"100%", backgroundSize: "cover", backgroundImage: `url(${culture.imageSource})`, position: "absolute", top:"-20px", left:"-20px"})} ></div>
        )}
        <h3>{culture.name}</h3>
        <Btn overrides={{Root:{style:{position:"absolute", top:"15px", right:"15px"}}}} shape={SHAPE.circle} size={SIZE.mini}onClick={() => setOpen(!open)}>{open ? (<RiArrowUpSFill/>) : (<RiArrowDownSFill/>)}</Btn>
      </PlainCardHeader>
      {open && (

        <ContentWrapper $noPad>
          <List>
            <ListItem sublist artwork={RiSeedlingFill} overrides={{Root:{style:{backgroundColor: "transparent"}}}}>
              <ListItemLabel sublist>
                {(culture.plantVarieties as PlantVariety[])?.map((variety) => variety.name).join(", ")}
              </ListItemLabel>
            </ListItem>
            <ListItem sublist artwork={RiStickyNoteFill} overrides={{Root:{style:{backgroundColor: "transparent"}}}}>
              <ListItemLabel sublist>
                {culture.comments[0].comment}
              </ListItemLabel> 
            </ListItem>
            <ListItem sublist artwork={GiGreenhouse} overrides={{Root:{style:{backgroundColor: "transparent"}}}}>
              <ListItemLabel sublist>
                {culture.place[0].place}
              </ListItemLabel>
            </ListItem>
            <ListItem sublist artwork={RiCalendar2Fill} overrides={{Root:{style:{backgroundColor: "transparent"}}}}>
              <ListItemLabel sublist>
                {culture.updatedAt.toDate().toLocaleDateString()}
              </ListItemLabel>
            </ListItem>
          </List>
          <ContentWrapper $flex $noPad $noMargin>
            {culture.pots.map(pot => ( 
              <div key={pot.id} className={css({transform:"scale(0.5)"})}>
                <Pot potType={pot.potType} potRows={getPotRows(pot)}></Pot>
              </div>
            ))}    
          </ContentWrapper>
        </ContentWrapper>
      )}
      {!hideActions && (
        <PlainCardFooter>
          <Btn color="danger" kind="tertiary" onClick={() => handleDelete(culture)}>Ta bort</Btn>
          <Btn>Uppdatera</Btn>
          {cultureToDelete && (
            <DeleteCulture isOpen={showDeleteCulture} onClose={() => setShowDeleteCulture(false)} culture={cultureToDelete}/>
          )}
        </PlainCardFooter>
      )}
    </PlainCard>
  );
};
 
export default CultureContent;