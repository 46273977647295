import { UserActionTypes } from "./actions";
import { SetUserAction, UserAction, UserState } from "./types";

export const initialState: UserState = {
  data: undefined
};

export const user = ( state: UserState = initialState, action: UserAction): UserState => {
  const {data} = <SetUserAction>action;
  switch(action.type) {

  case UserActionTypes.SetUser:
    

    return {
      ...state,
      data
    };

  default: return state;
  }
};