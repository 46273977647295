import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyCiEXb3i_oZfjmroAsGi748bGZWBs3Pg5M",
  authDomain: "saja.se",
  projectId: "farmplan-46f7b",
  storageBucket: "farmplan-46f7b.appspot.com",
  messagingSenderId: "1007243154721",
  appId: "1:1007243154721:web:b0e187111c9b899c885a60",
  measurementId: "G-9CH8FGVYVC"
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const providers = {
  googleProvider: new GoogleAuthProvider(),
};

export default app;
