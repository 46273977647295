import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from "baseui/modal";
import { FunctionComponent } from "react";
import { PublishingStatus } from "../../../../enums/PublishingStatus.enum";
import { Culture } from "../../../../interfaces/Culture.interface";
import { updateCulture } from "../../../../services/cultures/CulturesService";
import Btn from "../../../styled/Btn";
import ModalButtonFooter from "../../../styled/modal/ModalButtonFooter";


interface DeleteCultureProps {
    culture: Culture;
    isOpen: boolean;
    onClose: () => void;
}
 
const DeleteCulture: FunctionComponent<DeleteCultureProps> = (props) => {
  const {culture, isOpen, onClose} = props;

  const archiveCulture = () => {
    const newCulture = culture;
    newCulture.status = PublishingStatus.Archived;
    updateCulture(culture.id, newCulture).then(() => {
      onClose();
    });
  };

  const deleteCulture = () => {
    const newCulture = culture;
    newCulture.status = PublishingStatus.Deleted;
    updateCulture(culture.id, newCulture).then(() => {
      onClose();
    });
  };

  return ( 
    <Modal isOpen={isOpen} onClose={onClose} size="auto">
      <ModalHeader>
                Ta bort {culture.name}?
      </ModalHeader>
      <ModalBody>
                Vill du ta bort odlingen, eller arkivera en färdig odling?
                En arkiverad odling kommer vara del av statistik.
      </ModalBody>
      <ModalButtonFooter>
        <Btn kind="secondary" onClick={onClose}>
                    Ångra
        </Btn>
        <Btn onClick={archiveCulture}>Arkivera</Btn>
        <Btn color="danger" onClick={deleteCulture}>Ta bort</Btn>
      </ModalButtonFooter>
    </Modal>
  );
};
 
export default DeleteCulture;