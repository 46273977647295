import { createTheme } from "baseui";
import { replaceColorName } from "../../shared/utils/replaceColorName";
import { colors } from "./Colors";
import { mainOverrides } from "./MainTheme";
import { fontFamilies } from "./Typography";

const plantPrimitives = {
  ...replaceColorName("earth", "primary", colors.earth),
  ...replaceColorName("water", "accent", colors.water),
  ...replaceColorName("danger", "negative", colors.danger),
  ...replaceColorName("danger", "warning", colors.danger),
  ...replaceColorName("success", "positive", colors.success),
  ...fontFamilies
};

export const PlantTheme = createTheme(plantPrimitives, mainOverrides);