import { Cell, Grid } from "baseui/layout-grid";
import { HeadingMedium } from "baseui/typography";
import { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { ReactComponent as Farmer } from "../../../assets/images/farmer.svg";
import { ReduxService } from "../../../core/services/Redux";
import { CultureTheme } from "../../../core/theme/CultureTheme";
import CulturesAsIcons from "../../../shared/components/cultures-as-icons/CulturesAsIcons";
import ListCultures from "../../../shared/components/list-cultures/ListCultures";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import PlainCard from "../../../shared/components/styled/plain-card/PlainCard";
import { Themes } from "../../../shared/enums/Themes.enum";
import { User } from "../../../shared/interfaces/User.interface";
import { getMyCultures } from "../../../shared/services/cultures/CulturesService";
import MyPots from "../my-pots/MyPots";

interface MyPageProps {
  user: User;
}

const MyPage: FunctionComponent<MyPageProps> = (props) => {
  const { user } = props;
  const cultures = getMyCultures();

  useEffect(() => {
    ReduxService.instance.setTheme(Themes.User);
  }, []);

  return !user ? (
    <div>Inte inloggad</div>
  ) : (
    <Grid>
      <Cell span={[12]}>
        <ContentWrapper $noPad $flex $noMargin>
          <Farmer width="150px" />
          <HeadingMedium>
            {user.displayName || user.name}s hangout!
          </HeadingMedium>
        </ContentWrapper>
      </Cell>
      <Cell span={[4, 8, 6]}>
        <PlainCard>
          <CulturesAsIcons cultures={cultures}></CulturesAsIcons>
          {/* <ListCultures
          perPage={3}
          span={[12]}
          backgroundColor={CultureTheme.colors.primary50}
        ></ListCultures> */}
        </PlainCard>
      </Cell>
      <Cell span={[4, 8, 6]}>
        <MyPots></MyPots>
      </Cell>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(MyPage);
