import { combineReducers, createStore } from "redux";
import { AppState } from "./types";
import {user} from "./user/reducer";
import {theme} from "./theme/reducer";


export const setupStore = () => {
  const reducers = combineReducers<AppState>({
    user,
    theme
  });

  return createStore(reducers);
};