import { Themes } from "../../shared/enums/Themes.enum";
import { SetThemeAction } from "./types";


export enum ThemeActionTypes {
    SetTheme = "setTheme"
}

export const setTheme = (data: Themes): SetThemeAction => ({
  type:ThemeActionTypes.SetTheme,
  data
});