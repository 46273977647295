import { useStyletron } from "baseui";
import { styled } from "styletron-react";

interface ContentWrapperProps {
    $noPad?: boolean;
    $noMargin?: boolean;
    $backgroundColor?: string;
    $flex?: boolean;
    $flexColumn?: boolean;
    $flexAlign?: string;
    $flexJustify?: string;
    $gradientBackground?:boolean;
    $gradientColors?:string[]
}

const ContentWrapper = styled("div",(props: ContentWrapperProps) => {
  const {$flex, $flexColumn, $noPad, $backgroundColor, $flexAlign, $flexJustify, $noMargin, $gradientBackground, $gradientColors} = props;

  const [css, theme] = useStyletron();
  const gradient = `linear-gradient(to bottom, rgba(255,255,255,0),  ${$gradientColors ? $gradientColors[2] : 'rgba(255,255,255,0.5)'}) no-repeat border-box, linear-gradient(75deg, ${$gradientColors ? $gradientColors[0] : theme.colors.primary50}, 75%,  ${$gradientColors ? $gradientColors[1] : theme.colors.accent50}) no-repeat border-box `;
  return {
    boxSizing: "border-box",
    display: $flex ? "flex" : "block",
    flexDirection: $flex ? ($flexColumn ? "column" : "row") : "unset",
    width: $flex ? "100%" : "unset",
    alignItems: $flex ? ($flexAlign || "center") : "unset",
    justifyContent: $flex ? ($flexJustify || "center") : "unset",
    background: $gradientBackground ? gradient : $backgroundColor || "transparent",
    marginBottom: $noMargin ? 0 : theme.sizing.scale800,
    padding: $noPad ? "0" : theme.sizing.scale800
  };});

export default ContentWrapper;
