import { createTheme } from "baseui";
import { replaceColorName } from "../../shared/utils/replaceColorName";
import { colors } from "./Colors";
import { mainOverrides } from "./MainTheme";
import { fontFamilies } from "./Typography";

const culturePrimitives = {
  ...replaceColorName("plant", "primary", colors.plant),
  ...replaceColorName("terracotta", "accent", colors.terracotta),
  ...replaceColorName("danger", "negative", colors.danger),
  ...replaceColorName("danger", "warning", colors.danger),
  ...replaceColorName("success", "positive", colors.success),
  ...fontFamilies
};

export const CultureTheme = createTheme(culturePrimitives, mainOverrides);