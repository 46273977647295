
import { useStyletron } from "baseui";
import { InputProps } from "baseui/input";
import { Children, cloneElement, isValidElement, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { styled } from "styletron-react";
import Btn from "./Btn";


export default (props: PropsWithChildren<InputProps>) => {
  const {children} = props;
  const [newChildren, setNewChildren] = useState<ReactNode>(children);

  useEffect(() => {
    //NOTE: Make buttons square
    if(children) {
      const mappedChildren = Children.map(children, child => {
        if( isValidElement(child)) {
          if(child.type === Btn) {
            return cloneElement(child, {style: {...child.props.style, borderTopLeftRadius:0, borderBottomLeftRadius:0, flexShrink:0 }});   
          }
          return child;
        }
      });
      setNewChildren(mappedChildren);
    }
  }, [children]);

  const InputWrapper = styled("div",() => {
    const [css, theme] = useStyletron();
    return {
      display: "flex",
      flexDirection: "row",
      alignContent:"center",
      justifyContent:"flex-start",
      marginBottom: theme.sizing.scale600};
  });
  return (<InputWrapper children={newChildren} />);
};




