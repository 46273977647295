import { User as AuthUser } from "firebase/auth";
import { doc, getFirestore, onSnapshot, PartialWithFieldValue, serverTimestamp, setDoc } from "firebase/firestore";
import { app } from "../../../core/services/Firebase";
import { ReduxService } from "../../../core/services/Redux";
import UserConverter from "../../converters/UserConverter";
import { User } from "../../interfaces/User.interface";


const db =  getFirestore(app);
const converter = new UserConverter();


export async function syncUser(user:AuthUser) {
  
  if(!user) {
    return Promise.reject();
  }

  const document = doc(db, "users", user.uid).withConverter(converter);
  try{
    await setDoc(document, {
      name:user.displayName as string,
      id:user.uid,
      email: user.email as string,
      latestSignIn: serverTimestamp()}, {merge:true}
    );
  } catch (error) {
    return Promise.reject(error);
  }

  onSnapshot(document, (snap) => {
    const data = snap.data();
  
    if(data) {
      ReduxService.instance.setUser(data);
    } else {
      ReduxService.instance.setUser(null);
    }
  });
} 

export function getUserFromStore(): User {
  const data = ReduxService.instance.getUser();
  return data;
}


export async function updateUser(id:string, data:PartialWithFieldValue<User>): Promise<boolean> {
  const query = doc(db, "users", id).withConverter(converter);

  try{
    await setDoc(query, data, {merge:true});
  } catch (error) {
    return Promise.reject(error);
  }
 
  return Promise.resolve(true);
 
}