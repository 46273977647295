import { createTheme, Theme, ThemeProvider, useStyletron } from "baseui";
import { Button, ButtonProps, SHAPE, SIZE } from "baseui/button";
import { FunctionComponent, useEffect, useState } from "react";
import { mainOverrides } from "../../../core/theme/MainTheme";
import { replaceColorName } from "../../utils/replaceColorName";



interface BtnProps extends ButtonProps{
    color?: "primary" | "accent" | "danger" | "success";  
}

 
const Btn: FunctionComponent<BtnProps> = (props) => {
  const {color} = props;
  const [css, currentTheme] = useStyletron();
  const [theme, setTheme] = useState<Theme>(currentTheme);  

  const getAsPrimary = (color: string): Theme => {
    const colors:{[key:string]: string} = theme.colors as unknown as {[key:string]: string};
    const primitives = replaceColorName(color, "primary", colors);

   
    return createTheme(primitives, mainOverrides);
  };

  useEffect(() => {
    switch (color) {
    case "primary":
      setTheme(getAsPrimary("primary"));
      break;
    case "accent":
      setTheme(getAsPrimary("accent"));
      break;
    case "danger":
      setTheme(getAsPrimary("negative"));
      break;
    case "success": 
      setTheme(getAsPrimary("positive"));
      break;
    default:
      setTheme(getAsPrimary("primary"));

    }
  }, [color]);


  return ( 
    <ThemeProvider theme={theme}>
      <Button {...props}  overrides={{Root:{
        style:{
          boxSizing: "content-box",
          marginRight:"1px",
          ":first-of-type:not(:last-of-type)":{borderTopRightRadius: 0, borderBottomRightRadius:0,},
          ":last-of-type:not(:first-of-type)":{borderTopLeftRadius: 0, borderBottomLeftRadius:0,},
          ":not(:first-of-type):not(:last-of-type)": {borderTopLeftRadius: 0, borderBottomLeftRadius:0,borderTopRightRadius: 0, borderBottomRightRadius:0,},
          ":last-of-type": {marginRight: 0},
        }
      }}}></Button>
    </ThemeProvider>
  );
};

Btn.defaultProps = {
  size: SIZE.compact,
  shape: SHAPE.pill
};
 
export default Btn;