import { Cell, Grid } from "baseui/layout-grid";
import { Modal } from "baseui/modal";
import { FunctionComponent, useState } from "react";
import AddPot from "../../../../../../../shared/components/add-pot/AddPot";
import Pot from "../../../../../../../shared/components/pot/Pot";
import Btn from "../../../../../../../shared/components/styled/Btn";
import ContentWrapper from "../../../../../../../shared/components/styled/ContentWrapper";
import ModalButtonFooter from "../../../../../../../shared/components/styled/modal/ModalButtonFooter";
import { PotShape } from "../../../../../../../shared/enums/PotShape.enum";
import { PotType } from "../../../../../../../shared/interfaces/PotType.interface";
import { getMyPotTypes } from "../../../../../../../shared/services/pot-types/PotTypesService";


interface PickPotTypeProps {
    onPickPotType:(potType: PotType) => void;
    isOpen: boolean;
    onClose:() => void;
}
 
const PickPotType: FunctionComponent<PickPotTypeProps> = (props) => {
  const {onPickPotType, isOpen, onClose} = props;
  const [showAddPot, setShowAddPot] = useState<boolean>(false);

  const myPotTypes = getMyPotTypes();
    
  return ( 
    <Modal isOpen={isOpen} onClose={onClose}>
      <Grid>

        {myPotTypes && myPotTypes.filter(potType => potType.shape !== PotShape.Bed).map(potType => (
          <Cell  key={potType.id} span={[4,4,6]}>
            <ContentWrapper $flex $flexAlign="center" style={{transform:"scale(50%)", height:"100%"}} $noPad $noMargin>
              <div onClick={() => onPickPotType(potType)} style={{cursor:"pointer"}}>
                <Pot potType={potType}></Pot>
              </div>
            </ContentWrapper>
          </Cell>
        ))}
        <Cell  span={[4,4,6]}>
          <ContentWrapper $flex $flexAlign="center" style={{ height:"100%"}} $noPad $noMargin>
            <AddPot isOpen={showAddPot} onClose={() => setShowAddPot(false)}></AddPot>
          </ContentWrapper>
        </Cell>
      </Grid>
      <ModalButtonFooter>
        <Btn color="success" onClick={() => setShowAddPot(true)}>Ny kruka</Btn>
      </ModalButtonFooter>
    </Modal>
  );
};
 


export default PickPotType;