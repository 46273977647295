import { ThemeActionTypes } from "./actions";
import { SetThemeAction, ThemeAction, ThemeState } from "./types";

export const initialState: ThemeState = {
  data: undefined
};

export const theme = ( state: ThemeState = initialState, action: ThemeAction): ThemeState => {
  const {data} = <SetThemeAction>action;
  switch(action.type) {

  case ThemeActionTypes.SetTheme:
    

    return {
      ...state,
      data
    };

  default: return state;
  }
};