import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp } from "firebase/firestore";
import { ReduxService } from "../../core/services/Redux";
import { PotType } from "../interfaces/PotType.interface";

class PotTypesConverter implements FirestoreDataConverter<PotType> {
  toFirestore(event: PartialWithFieldValue<PotType>): DocumentData {
    const user = ReduxService.instance.getUser();
    const slotsOrder = event.slotsOrder as number[];
    const noSlots: number = event.multi ? slotsOrder.reduce((number, slot) =>  number+slot,0) : 1;
    return {
      ...event,
      noSlots,
      owner: event.owner || user.id, 
      createdAt: event.createdAt || serverTimestamp(),
      updatedAt: serverTimestamp()
    };
  }

  fromFirestore(snap: QueryDocumentSnapshot): PotType {
    const data = snap.data();
    const id = snap.id;
    return {...data, id} as PotType;
  }
}

export default PotTypesConverter;