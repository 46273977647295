import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Cell, Grid } from "baseui/layout-grid";
import { Textarea } from "baseui/textarea";
import { HeadingMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReduxService } from "../../../core/services/Redux";
import Btn from "../../../shared/components/styled/Btn";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import PlainCard from "../../../shared/components/styled/plain-card/PlainCard";
import UploadImage from "../../../shared/components/upload-image/UploadImage";
import { Themes } from "../../../shared/enums/Themes.enum";
import { PlantCategory } from "../../../shared/interfaces/PlantCategory.interface";
import { PlantType } from "../../../shared/interfaces/PlantType.interface";
import {
  getPlantType,
  savePlantType,
  updatePlantType,
} from "../../../shared/services/plant-type/PlantTypeService";
import SetPlantTypeCategories from "./components/set-plant-type-categories/SetPlantTypeCategories";

const EditPlantType = () => {
  const { typeId } = useParams();
  const plantType = getPlantType(typeId || "");
  const [name, setName] = useState<string>(plantType.name);
  const [latinName, setLatinName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string>("");
  const [imageSource, setImageSource] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const nav = useNavigate();

  useEffect(() => {
    ReduxService.instance.setTheme(Themes.Plant);
  }, []);

  useEffect(() => {
    let mounted = true;

    if (mounted && !saving) {
      if (typeId === "new") {
        //
      }
      if (typeId && plantType) {
        setName(plantType.name);
        if (plantType.latinName) {
          setLatinName(plantType.latinName);
        }
        if (plantType.description) {
          setDescription(plantType.description);
        }
        if (plantType?.categories && plantType.categories.length > 0) {
          const cat: PlantCategory = plantType.categories[0] as PlantCategory;
          setCategory(cat.id);
          if (cat.subCategories && cat.subCategories.length > 0) {
            setSubCategory(cat.subCategories[0].id);
          }
        }
        if (plantType?.imageSource) {
          setImageSource(plantType.imageSource);
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, [typeId, plantType]);

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleLatinNameChange = (event: any) => {
    setLatinName(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const onSubmit = () => {
    setSaving(true);
    if (!typeId || typeId === "new") {
      const data: Partial<PlantType> = {
        name,
        latinName,
        description,
        imageSource,
      };
      savePlantType(data, category, subCategory).then(() => {
        nav("/plants/list");
      });
    }
    if (typeId && typeId !== "new") {
      const data: Partial<PlantType> = {
        name,
        latinName,
        description,
        imageSource,
      };
      updatePlantType(typeId, data, category, subCategory).then(() => {
        nav("/plants/list");
      });
    }
  };

  return saving ? (
    <div>saving</div>
  ) : (
    <div>
      <ContentWrapper $noPad>
        {typeId === "new" ? (
          <HeadingMedium>Ny planttyp</HeadingMedium>
        ) : (
          <HeadingMedium>Redigerar {plantType.name}</HeadingMedium>
        )}
      </ContentWrapper>
      <Grid>
        <Cell span={[6]}>
          <ContentWrapper $noPad>
            <PlainCard>
              <FormControl label="Namn">
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                ></Input>
              </FormControl>
              <FormControl label="Latinskt namn">
                <Input
                  id="latin-name"
                  name="latin-name"
                  type="text"
                  value={latinName}
                  onChange={handleLatinNameChange}
                ></Input>
              </FormControl>
              <FormControl label="Beskrivning">
                <Textarea
                  id="description"
                  name="description"
                  type="text"
                  value={description}
                  onChange={handleDescriptionChange}
                ></Textarea>
              </FormControl>
            </PlainCard>
          </ContentWrapper>
        </Cell>
        <Cell span={[6]}>
          <SetPlantTypeCategories
            category={category}
            setCategory={(id) => setCategory(id)}
            subCategory={subCategory}
            setSubCategory={(id) => setSubCategory(id)}
          ></SetPlantTypeCategories>
        </Cell>
        <Cell span={[6]}>
          <ContentWrapper $noPad>
            <PlainCard>
              <UploadImage
                imageUrl={imageSource}
                onSuccess={(url) => setImageSource(url)}
              />
            </PlainCard>
          </ContentWrapper>
        </Cell>
      </Grid>
      <ContentWrapper>
        <Btn
          color="success"
          disabled={!name || !category || !subCategory}
          onClick={onSubmit}
        >
          SPARA
        </Btn>
      </ContentWrapper>
    </div>
  );
};

export default EditPlantType;
