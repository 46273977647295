import { addDoc, collection, deleteDoc, doc, getDoc, getFirestore, onSnapshot, PartialWithFieldValue, query, where, writeBatch } from "firebase/firestore";
import { useEffect, useState } from "react";
import { app } from "../../../core/services/Firebase";
import PlantCategoryConverter from "../../converters/PlantCategoryConverter";
import PlantSubCategoryConverter from "../../converters/PlantSubCategoryConverter";
import { PlantCategory } from "../../interfaces/PlantCategory.interface";
import { PlantSubCategory } from "../../interfaces/PlantSubCategory.interface";
import { getPlantTypes } from "../plant-type/PlantTypeService";

const db =  getFirestore(app);
const converter = new PlantSubCategoryConverter();
const catConverter = new PlantCategoryConverter();

export function getPlantSubCategories(parentId?: string): PlantSubCategory[]  {
  const [subCats, setSubCats] = useState<PlantSubCategory[]>([]);
  const types = getPlantTypes();

  const col = collection(db, "plantSubCategory").withConverter(converter);
  const parent = parentId ? doc(db, "plantCategory", parentId) : null;
  useEffect(() => {
    onSnapshot(parentId && parent ? query(col, where("parent", "==", parent)) : col, (querySnap) => {
      let data = querySnap.docs.map(doc => doc.data());
      
      if(types) {
        const newData = data.map(subcat => {
          const newTypes = types.filter(type => type.categories?.map(cat => cat.id).includes(subcat.id));
          return {...subcat, planTypes: newTypes};
        });
        data = newData;
      }
      setSubCats(data);
    });        
  }, [parentId]);  
  return subCats;
}
  
export async function getPlantSubCategory(id: string): Promise<PlantSubCategory>  {
  const query = doc(db, "plantSubCategory", id).withConverter(converter);
  const snapshot = await getDoc<PlantSubCategory>(query);
  const data = snapshot.data();
  if(data) {
    return Promise.resolve(data);
  } else {
    return Promise.reject("No cat found");
  }
}
  
export async function savePlantSubCategory(parentId: string, data: PartialWithFieldValue<PlantSubCategory>): Promise<PlantCategory>  {

  const query = collection(db, "plantSubCategory").withConverter(converter);
  const parentCat = doc(db, "plantCategory", parentId).withConverter(catConverter);
  const newData = {...data, parent: parentCat};


  let docRef;
  try {
    docRef = await addDoc(query, newData);
  } catch (error) {
    return Promise.reject(error);
  }
  let snapshot;
  try {
    snapshot = await getDoc(docRef.withConverter(converter));
  } catch (error) {
    return Promise.reject(error);
  }
  const result = snapshot.data();
  if(result) {
    return Promise.resolve(result);
  } else {
    return Promise.reject("No cat returned");
  }
}
  
export const updatePlantSubCategories = async(data: PartialWithFieldValue<PlantSubCategory>[]): Promise<boolean> => {

  const batch = writeBatch(db);
  const col = collection(db, "plantSubCategory").withConverter(converter);
    
  data.forEach(subcat =>{ 
    const updatedSubCat = {
      ...subcat,
    };
    
    const query = doc(col, subcat.id as string);
    batch.set(query, updatedSubCat, {merge:true});
  
  }); 

  try {
    batch.commit();
  } catch (error) {
    return Promise.reject(error);
  }
  return Promise.resolve(true);
};
  
export async function deletePlantSubCategory(id:string): Promise<boolean>  {
  const query = doc(db, "plantSubCategory", id).withConverter(converter);
    
  try {
    await deleteDoc(query);
  } catch (error) {
    return Promise.reject(error);
  }
      
  return Promise.resolve(true);
}