import { addDoc, collection, doc, DocumentReference, getDoc, getFirestore, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { app } from "../../../core/services/Firebase";
import PlantTypeConverter from "../../converters/PlantTypeConverter";
import PlantVarietyConverter from "../../converters/PlantVarietyConverter";
import { PlantType } from "../../interfaces/PlantType.interface";
import { PlantVariety } from "../../interfaces/PlantVariety.interface";


const db =  getFirestore(app);
const converter = new PlantVarietyConverter();
const typeConverter = new PlantTypeConverter();

export function getPlantVarieties(typeIds?: string[]): PlantVariety[] {
  const [varieties, setVarieties] = useState<PlantVariety[]>([]);
      
  const col = collection(db, "plantVariety").withConverter(converter);
      
  useEffect(() => {
    onSnapshot(typeIds && typeIds.length ? query(col,  where("plantTypeId", "in", typeIds)) : col, (querySnap) => {
      const data = querySnap.docs.map(doc => doc.data());
      setVarieties(data);
    });
               
  }, [typeIds]);
      
  return varieties;
}

export function getPlantVariety(id:string): PlantVariety {
  const [variety, setVariety] = useState<Partial<PlantVariety>>({name:"", id:"",});
  const [query, setQuery] = useState<DocumentReference<PlantVariety>>();
  const [type, setType] = useState<PlantType>();
   
  useEffect(() => {
    if(id) {
      setQuery(doc(db, "plantVariety", id).withConverter(converter));
    }
  }, [id]);
    
  const handleType = (doc: DocumentReference<PlantType>) => {
    onSnapshot(doc, snap => {
      const data = snap.data();
      if(data) {
        setType(data);
      }
    });
  };

  useEffect(() => {
    if(query) {
      onSnapshot(query, (snap) => {
        const data = snap.data();
        if(data) { 
          if(data.plantType) {
            handleType(data.plantType as DocumentReference<PlantType>);
          }
          setVariety(data);
        }
      }); 
    }
  }, [query]);

  useEffect(() => {
    if(variety && type) {
      const newVar = variety;
      newVar.plantType = type;
      setVariety(newVar);
    }
  }, [variety, type]);
  
  return variety as PlantVariety;
}


export const savePlantVariety = async (data: Partial<PlantVariety>, typeId: string): Promise<PlantVariety>  => {

  const query = collection(db, "plantVariety").withConverter(converter);
  const type = doc(db, "plantType", typeId).withConverter(typeConverter);
  
  data.plantType = type;
  let docRef;
  try {
    docRef = await addDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }
  let snapshot;
  try {
    snapshot = await getDoc(docRef.withConverter(converter));
  } catch (error) {
    return Promise.reject(error);
  }
  const result = snapshot.data();
  if(result) {
    return Promise.resolve(result);
  } else {
    return Promise.reject("No plant type returned");
  }
};
  

export const updatePlantVariety = async (id:string, data: Partial<PlantVariety>, typeId?:string): Promise<boolean> =>{

  const query = doc(db, "plantVariety", id).withConverter(converter);
  const type = typeId ?  doc(db, "plantType", typeId).withConverter(typeConverter) : null;
 
  if(type) {
    data.plantType = type;
  }
    
  try {
    await setDoc(query, data);
  } catch (error) {
    return Promise.reject(error);
  }
    
  return Promise.resolve(true);
    
};