import { getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import app from "../../../core/services/Firebase";

const storage = getStorage(app);


export async function uploadImage(image: File): Promise<string> {
  console.log(image);
  if(!image.name) {
    return Promise.reject();
  }
  const ref = storageRef(storage, `/images/${image.name}`);

  try {
    await uploadBytes(ref, image);
  } catch (error) {
    return Promise.reject(error);
  }

  let fullurl;
  try {
    fullurl = await getDownloadURL(ref);
  } catch (error) {
    return Promise.reject(error);
  }
  return Promise.resolve(fullurl);

    
}