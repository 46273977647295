import { styled, useStyletron } from "baseui";
import { ModalProps } from "baseui/modal";
import { FunctionComponent } from "react";
import ModalButtonFooterContent from "./ModalButtonFooterContent";



const ModalButtonFooterWrapper = styled("div", () => {
  const [css, theme] = useStyletron();
  return { 
    boxSizing:"border-box", 
    height:theme.sizing.scale1000,
    width:"100%",
  };
});
  
  
const ModalButtonFooter : FunctionComponent<ModalProps> = (props) => {
  return ( 
    <ModalButtonFooterWrapper >
      <ModalButtonFooterContent>{props.children}
      </ModalButtonFooterContent>
    </ModalButtonFooterWrapper> 
  );
};
 


export default ModalButtonFooter;