

export const colors = {
  water: {
    water: "#2D6BAE",

    water50: "#DFEAF7",
    water100: "#AECBEA",
    water200: "#7DACDE",
    water300: "#4D8DD1",

    water400: "#2D6BAE",

    water500: "#215082",
    water600: "#153251",
    water700: "#081420",
  },
  earth: {
    earth: "#846B5C",

    earth50: "#EEEAE7",
    earth100: "#D5CAC3",
    earth200: "#BCA99F",
    earth300: "#A3897B",

    earth400: "#846B5C",

    earth500: "#604E43",
    earth600: "#483A32",
    earth700: "#302721",
  },
  plant: {
    plant: "#88CB69",

    plant50: "#E7F5E1",
    plant100: "#CFEAC2",
    plant200: "#B7E0A4",
    plant300: "#9FD585",

    plant400: "#88CB69",

    plant500: "#64B63E",
    plant600: "#4B892F",
    plant700: "#325B1F",
  },
  terracotta: {
    terracotta: "#DF9133",

    terracotta50: "#FCF6ED",
    terracotta100: "#F7E3CA",
    terracotta200: "#EEC695",
    terracotta300: "#E6AA60",

    terracotta400: "#DF9133",

    terracotta500: "#B06D1C",
    terracotta600: "#7C4D13",
    terracotta700: "#472C0B"
  },
  danger: {
    danger: "#EB5F2C",

    danger50: "#FDF1EC",
    danger100: "#FAD5C7",
    danger200: "#F5AA8F",
    danger300: "#EF8057",

    danger400: "#EB5F2C",

    danger500: "#CD4513",
    danger600: "#832C0C",
    danger700: "#4B1907"
  },
  success: {
    success: "#66B32C",

    success50: "#C8EBAD",
    success100: "#B2E38C",
    success200: "#91D75B",
    success300: "#7BCF3A",

    success400: "#66B32C",

    success500: "#5EA428",
    success600: "#4B8320",
    success700: "#386318"
  }
};


