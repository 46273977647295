
import { Modal, ModalBody, ModalHeader, ROLE, SIZE  } from "baseui/modal";
import { FunctionComponent } from "react";
import ListPlants from "../../../../../../../shared/components/list-plants/ListPlants";


interface FindPlantVarietyTypeProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void;
    setPickedType: (id: string) => void;
}
 
const FindPlantVarietyType: FunctionComponent<FindPlantVarietyTypeProps> = (props) => {
  const {isOpen, setIsOpen, setPickedType} = props;

  const handlePickType = (id:string) => {
    setPickedType(id);
    setIsOpen(false);
  };
  return (
    <Modal
      onClose={() => setIsOpen(false)}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.full}
      role={ROLE.dialog}
    >
      <ModalHeader>Välj växttyp</ModalHeader>
      <ModalBody>

        <ListPlants $disableEdit onPickType={(id) => handlePickType(id)} $hideVariety></ListPlants>
      </ModalBody>
    </Modal>
  );
};
 
export default FindPlantVarietyType;