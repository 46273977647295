import { createTheme } from "baseui";
import { replaceColorName } from "../../shared/utils/replaceColorName";
import { colors } from "./Colors";
import { fontFamilies, typographyOverrides } from "./Typography";

export const mainPrimitives = {
  ...replaceColorName("earth", "primary", colors.earth),
  ...replaceColorName("terracotta", "accent", colors.terracotta),
  ...replaceColorName("danger", "negative", colors.danger),
  ...replaceColorName("danger", "warning", colors.danger),
  ...replaceColorName("success", "positive", colors.success),
  ...fontFamilies,
};
export const mainOverrides = {
  typography: typographyOverrides,

  lighting: {
    shadow400:"0 8px 8px 0 hsla(0, 0%, 0%, 0.05)"
  }
};
export const MainTheme = createTheme(mainPrimitives, mainOverrides);