import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp } from "firebase/firestore";
import { ReduxService } from "../../core/services/Redux";
import { PlantVariety } from "../interfaces/PlantVariety.interface";

class PlantVarietyConverter implements FirestoreDataConverter<PlantVariety> {
  toFirestore(event: PartialWithFieldValue<PlantVariety>): DocumentData {
    const user = ReduxService.instance.getUser();
    return {
      ...event,
      createdBy: event.createdBy || user.id, 
      createdAt: event.createdAt || serverTimestamp(),
      updatedBy: user.id, 
      updatedAt: serverTimestamp()
    };
  }
  fromFirestore(snap: QueryDocumentSnapshot): PlantVariety {
    const data = snap.data();
    const id = snap.id;
    return {...data, id} as PlantVariety;
  }
}

export default PlantVarietyConverter;