import { DocumentData, FirestoreDataConverter, PartialWithFieldValue, QueryDocumentSnapshot, serverTimestamp } from "firebase/firestore";
import { ReduxService } from "../../core/services/Redux";
import { PlantCategory } from "../interfaces/PlantCategory.interface";
class PlantCategoryConverter implements FirestoreDataConverter<PlantCategory> {


  toFirestore(event: PartialWithFieldValue<PlantCategory>): DocumentData { 
    const user = ReduxService.instance.getUser();
    return {
      ...event, 
      createdBy: event.createdBy || user.id, 
      createdAt: event.createdAt || serverTimestamp(),
      updatedBy: user.id, 
      updatedAt: serverTimestamp()};
  }
  fromFirestore(snap: QueryDocumentSnapshot): PlantCategory {
    const data = snap.data();
    const id = snap.id;
    return {...data, id} as PlantCategory;
  }
}

export default PlantCategoryConverter;