import { KIND } from "baseui/button";
import { Cell, Grid } from "baseui/layout-grid";
import { StyledListItem } from "baseui/menu";
import { HeadingMedium } from "baseui/typography";
import { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import AddPot from "../../../shared/components/add-pot/AddPot";
import Pot from "../../../shared/components/pot/Pot";
import Btn from "../../../shared/components/styled/Btn";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import List from "../../../shared/components/styled/List";
import { PotType } from "../../../shared/interfaces/PotType.interface";
import { User } from "../../../shared/interfaces/User.interface";
import { getMyPotTypes } from "../../../shared/services/pot-types/PotTypesService";
import DeletePot from "./components/delete-pot/DeletePot";

interface MyPotsProps {
  user: User;
}

const MyPots: FunctionComponent<MyPotsProps> = (props) => {
  const { user } = props;
  const [showAddPot, setShowAddPot] = useState<boolean>(false);
  const [showDeletePot, setShowDeletePot] = useState<boolean>(false);
  const [potToDelete, setPotToDelete] = useState<PotType>();
  const pots = getMyPotTypes();

  const getPotRows = (slots: number[]): number[][] => {
    return slots.map((slot) => {
      const slots = [];
      for (let index = 0; index < slot; index++) {
        slots.push(slot);
      }
      return slots;
    });
  };

  const deletePot = (pot: PotType) => {
    setPotToDelete(pot);
    setShowDeletePot(true);
  };

  return !user || !pots ? (
    <div>Loading</div>
  ) : (
    <ContentWrapper $noPad>
      <Grid>
        <Cell span={[12]}>
          <HeadingMedium>Mina krukor</HeadingMedium>
        </Cell>
        {pots.map((pot) => (
          <Cell span={[12]} key={pot.id}>
            <ContentWrapper $noPad>
              <Grid>
                <Cell span={[8]}>
                  <ContentWrapper
                    $noPad
                    $flex
                    $flexAlign="center"
                    $flexJustify="center"
                  >
                    <Pot potType={pot}></Pot>
                  </ContentWrapper>
                </Cell>
                <Cell span={[4]}>
                  <List>
                    <StyledListItem>Antal pottar: {pot.noSlots}</StyledListItem>
                  </List>
                  <Btn
                    kind={KIND.secondary}
                    color="danger"
                    onClick={() => deletePot(pot)}
                  >
                    TA BORT
                  </Btn>
                </Cell>
              </Grid>
            </ContentWrapper>
          </Cell>
        ))}

        <AddPot
          isOpen={showAddPot}
          onClose={() => setShowAddPot(false)}
        ></AddPot>
        {potToDelete && (
          <DeletePot
            isOpen={showDeletePot}
            onClose={() => setShowDeletePot(false)}
            pot={potToDelete}
          ></DeletePot>
        )}
      </Grid>

      <Btn color="success" onClick={() => setShowAddPot(true)}>
        LÄGG TILL
      </Btn>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
  };
};
export default connect(mapStateToProps)(MyPots);
