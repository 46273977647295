
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { FormControl } from "baseui/form-control";
import { Cell, Grid } from "baseui/layout-grid";
import { FunctionComponent, useEffect, useState } from "react";
import Pot from "../../../../../shared/components/pot/Pot";
import Btn from "../../../../../shared/components/styled/Btn";
import ContentWrapper from "../../../../../shared/components/styled/ContentWrapper";
import PlainCard from "../../../../../shared/components/styled/plain-card/PlainCard";
import PlainCardFooter from "../../../../../shared/components/styled/plain-card/PlainCardFooter";
import { CultureStatus } from "../../../../../shared/enums/CultureStatus.enum";
import { GrowthProgress } from "../../../../../shared/enums/GrowthProgress.enum";
import { PotShape } from "../../../../../shared/enums/PotShape.enum";
import { PlantVariety } from "../../../../../shared/interfaces/PlantVariety.interface";
import { Pot as PotInterface } from "../../../../../shared/interfaces/Pot.interface";
import { PotSlot } from "../../../../../shared/interfaces/PotSlot.interface";
import { PotType } from "../../../../../shared/interfaces/PotType.interface";
import PickPotType from "./components/pick-pot-type/PickPotType";
import PotVarietyAndProgress from "./components/pot-variety-and-progress/PotVarietyAndProgress";


interface EditCulturePartProps {
    pot: PotInterface;
    index: number;
    multiVariety: boolean;
    onValueChange:(pot: PotInterface) => void;
}
 
const EditCulturePart: FunctionComponent<EditCulturePartProps> = (props) => {
  const {index, multiVariety, onValueChange} = props;
  const [pot, setPot] = useState<PotInterface>(props.pot);
  const [showPickPotType, setShowPickPotType] = useState<boolean>(false);

  useEffect(() => {
    if(props.pot !== pot) {
      onValueChange(pot);
    }
  }, [pot]);

  const changePot = (potType: PotType) => {
    const oldRows = pot.rows;
    const startDate = oldRows[0][0][0].changeDate;

    let newRows:{[key:string]: {[key:string]: PotSlot[]}} = {0:{0:[{noSeeds:1, plantVariety:oldRows[0][0][0].plantVariety, progress: oldRows[0][0][0].progress, status:CultureStatus.Active, changeDate:startDate}]}};
    
    if(potType.multi && potType.slotsOrder) {
      newRows = potType.slotsOrder.reduce((map, row, index) => {

        let columns: {[id:string]: PotSlot[]} = {};

        for (let i = 0; i < row; i++) {
          let oldProgress = oldRows[0][0][0].progress;
          let oldVariety = oldRows[0][0][0].plantVariety;
          if(pot.potType.multi && oldRows[index] && oldRows[index][i]) {
            oldProgress = oldRows[index][i][0].progress;
            oldVariety = oldRows[index][i][0].plantVariety;
          }
          columns = {...columns, [i]:[{noSeeds:1, progress: oldProgress, plantVariety: oldVariety, status:CultureStatus.Active, changeDate:startDate}]
          };
        }
        map = {...map, [index]:columns};
        return map;
      }, {});
    }
    const newPot = {
      ...pot,
      multiVariety: potType.multi ? pot.multiVariety : false,
      potType: potType,
      rows: newRows,
    };
   
    setPot(newPot);
    console.log("New pot", newPot);
    setShowPickPotType(false);
  };

  const setSinglePotVariety = (variety: PlantVariety): void => {
    const rows = pot.rows;
    const rowKeys = Object.keys(rows);
    rowKeys.forEach(rowKey => {
      const columnKeys = Object.keys(rows[+rowKey]);
      columnKeys.forEach(columnKey => {
        rows[+rowKey][+columnKey][0].plantVariety = variety;
      });
    });
    setPot({...pot, rows});
  };

  const setMultiPotVariety = (row:number, column:number, variety: PlantVariety): void => {
    const rows = pot.rows;
    rows[row][column][0].plantVariety = variety;
    setPot({...pot, rows});
  };

  const getPotRows = (pot:PotInterface): GrowthProgress[][] => {
    const potRows = Object.values(pot.rows).map(row => {
      return Object.values(row).map(col => col[0].progress);
    });
    return potRows;
  };

  const setSinglePotProgress  = (value: GrowthProgress) => {
    const rows = pot.rows;
    const rowKeys = Object.keys(rows);
    rowKeys.forEach(rowKey => {
      const columnKeys = Object.keys(rows[+rowKey]);
      columnKeys.forEach(columnKey => {
        rows[+rowKey][+columnKey][0].progress = value;
      });
    });
  
    setPot({...pot, rows});
  };

  const setMultiPotProgress  = (row:number, column: number, value: GrowthProgress) => {
    const rows = pot.rows;
    rows[row][column][0].progress = value;
    setPot({...pot, rows});
  };

  const setSinglePotNoSeeds  = ( value: number) => {
    const rows = pot.rows;
    const rowKeys = Object.keys(rows);
    rowKeys.forEach(rowKey => {
      const columnKeys = Object.keys(rows[+rowKey]);
      columnKeys.forEach(columnKey => {
        rows[+rowKey][+columnKey][0].noSeeds = value;
      });
    });
    setPot({...pot, rows});
  };

  const setMultiPotNoSeeds  = ( row:number, column:number, value: number) => {
    const rows = pot.rows;
    rows[row][column][0].noSeeds = value;
    setPot({...pot, rows});
  };

  const setPotMultiVariety = (checked: boolean) => {
    const newPot = {...pot};
    newPot.multiVariety = checked;
    setPot(newPot);
  };


  return ( <Grid>
    <Cell span={[4, 4, 6]}>
      <ContentWrapper $noPad>
        <PlainCard>
          <ContentWrapper $noPad $noMargin style={{marginTop:"-30px"}}>
            <Pot  potType={pot.potType} potRows={getPotRows(pot)}  progress={pot.rows[0][0][0].progress}></Pot>
          </ContentWrapper>
    
          {pot.potType.shape !== PotShape.Bed && (
            <PlainCardFooter>
              <Btn color="accent" onClick={() => setShowPickPotType(true)}>Byt kruka</Btn>
              <PickPotType isOpen={showPickPotType} onClose={() => setShowPickPotType(false)} onPickPotType={(potType) => changePot(potType)}/>
            </PlainCardFooter>
          )}
        </PlainCard>
      </ContentWrapper>
    </Cell>
    <Cell span={[4,4,6]}>
      {pot.potType.multi && multiVariety && (
        <FormControl label={pot.multiVariety ? "Flera sorter" : "En sort"}>
          <Checkbox checked={pot.multiVariety} onChange={(e:any) => setPotMultiVariety(e.target.checked)}    checkmarkType={STYLE_TYPE.toggle_round}
            labelPlacement={LABEL_PLACEMENT.right}>
        Krukan har olika sorter
          </Checkbox>
        </FormControl>
      ) }     
    </Cell>

    {!pot.multiVariety || !multiVariety ? (
      <Cell span={[4,8,6]}>  
        <ContentWrapper $noPad>
          <PlainCard>
            <PotVarietyAndProgress
              multiVariety={multiVariety}
              plantVariety={pot?.rows[0][0][0]?.plantVariety as PlantVariety}
              onPickVariety={(variety) => setSinglePotVariety(variety)}
              onSetProgress={(value) => setSinglePotProgress(value)}
              progress={pot?.rows[0][0][0]?.progress}
              noSeeds={pot?.rows[0][0][0]?.noSeeds}
              onSetNoSeeds={(value) => setSinglePotNoSeeds(value)}
            />
          </PlainCard>
        </ContentWrapper>
      </Cell>
    ): (
      <>
        {Object.keys(pot.rows).map(row => (
          <Cell key={`row-${row}`} span={[12]}>
            <ContentWrapper $noPad>
              <PlainCard>
                <header>
                  <h3>Rad {+row+1}</h3>
                </header>
                <Grid>

                  {Object.keys(pot.rows[+row]).map(column => (
                    <Cell key={`column-${column}`} span={[4,4,4]}>
                      <ContentWrapper $noPad>
                        <div>Pot {+column+1}</div>
                        <PotVarietyAndProgress
                          multiVariety={multiVariety}
                          plantVariety={pot?.rows[+row][+column][0]?.plantVariety as PlantVariety}
                          onPickVariety={(variety) => setMultiPotVariety(+row, +column, variety)}
                          onSetProgress={(value) => setMultiPotProgress(+row, +column, value)}
                          progress={pot?.rows[+row][+column][0]?.progress}
                          noSeeds={pot?.rows[+row][+column][0]?.noSeeds}
                          onSetNoSeeds={(value) => setMultiPotNoSeeds(+row, +column, value)}
                        />
                      </ContentWrapper>
                    </Cell>
                  ))}
                </Grid>
              </PlainCard>
            </ContentWrapper>
          </Cell>
        ))}
      </>
    )}

  </Grid> );
};
 
export default EditCulturePart;