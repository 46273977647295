import { KIND } from "baseui/button";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import { FunctionComponent } from "react";
import Pot from "../../../../../shared/components/pot/Pot";
import Btn from "../../../../../shared/components/styled/Btn";
import ModalButtonFooter from "../../../../../shared/components/styled/modal/ModalButtonFooter";
import { PotType } from "../../../../../shared/interfaces/PotType.interface";
import { deletePotType } from "../../../../../shared/services/pot-types/PotTypesService";



interface DeletePotProps {
    isOpen: boolean;
    onClose: () => void;
    pot:PotType;
}
 
const DeletePot: FunctionComponent<DeletePotProps> = (props) => {
  const {isOpen, onClose, pot} = props;

  const onSubmit = () => {
    deletePotType(pot.id).then(() => {
      onClose();
    });
  };

  return ( 
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
       Vill du ta bort denna kruka?
      </ModalHeader>
      <ModalBody>
        <Pot potType={pot}></Pot>
      </ModalBody>
      <ModalButtonFooter>
        <Btn color="success"kind={KIND.secondary} onClick={onClose}>
              Avbryt
        </Btn>
        <Btn color="danger" onClick={onSubmit}>Ta bort</Btn>
      </ModalButtonFooter>
    </Modal>
  );
};
 
export default DeletePot;