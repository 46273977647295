import { styled } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { LabelXSmall } from "baseui/typography";
import { FunctionComponent } from "react";
import { Culture } from "../../interfaces/Culture.interface";
import { getPotRows } from "../../utils/getPotRows";
import Pot from "../pot/Pot";
import ContentWrapper from "../styled/ContentWrapper";

interface CulturesAsIconsProps {
  cultures: Culture[];
}

export const CircleWrapper = styled("div", ({ $theme }) => {
  return {
    width: "50px",
    height: "50px",
    borderRadius: "100%",
    backgroundColor: $theme.colors.primary100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: 1,
  };
});
const CulturesAsIcons: FunctionComponent<CulturesAsIconsProps> = (props) => {
  const { cultures } = props;

  return (
    <Grid>
      {cultures.map((culture) => (
        <Cell key={culture.id} span={[2, 2, 3]}>
          <ContentWrapper
            $noPad
            $flex
            style={{ height: "100px", width: "100px", position: "relative" }}
          >
            <CircleWrapper>
              {culture.pots.map((pot) => (
                <Pot
                  scale={75 / culture.pots.length}
                  potType={pot.potType}
                  potRows={getPotRows(pot)}
                ></Pot>
              ))}
            </CircleWrapper>

            <LabelXSmall
              $style={{
                position: "absolute",
                bottom: 0,
                width: "100px",
                textAlign: "center",
              }}
            >
              {culture.name}
            </LabelXSmall>
          </ContentWrapper>
        </Cell>
      ))}
    </Grid>
  );
};

export default CulturesAsIcons;
