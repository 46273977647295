import { useStyletron } from "baseui";
import { styled } from "styletron-react";

interface ListProps {
    $noPad?: boolean;
    
}

const List = styled("ul",(props: ListProps) => {
  const [css, theme] = useStyletron();
  return {
    margin: 0,
    padding: props.$noPad ? "0" : theme.sizing.scale600,
    listStyle:"none"
 
  };}
);

export default List;
