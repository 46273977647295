import { styled, useStyletron } from "baseui";
import { SIZE } from "baseui/button";
import { ModalProps } from "baseui/modal";
import { Children, cloneElement, isValidElement, ReactNode, useEffect, useState } from "react";
import Btn from "../Btn";





export default (props: ModalProps) => {
  const { children } = props;
  const [newChildren, setNewChildren] = useState<ReactNode>(children);

  useEffect(() => {
  //NOTE: Make buttons square
    if(children) {
      const mappedChildren = Children.map(children, child => {
        if( isValidElement(child)) {
          if(child.type === Btn) {
            return cloneElement(child, {style: {...child.props.style, borderRadius:0 }, size:SIZE.default});   
          }
          return child;
        }
      });
      setNewChildren(mappedChildren);
    }
  }, [children]);


  const ModalButtonFooterContent = styled("div",() => {

    const [css, theme] = useStyletron();
    return {
      position:"absolute",
      bottom:0,
      right:0,
      left:0,
      boxSizing:"border-box", 
      borderRadius: `0 0 ${theme.borders.radius400} ${theme.borders.radius400}`,
      overflow:"hidden",
      display:"flex",
      justifyContent:"flex-end",
      alignItems:"flex-end",
      "> button": {
        borderRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      }
    };});
  return ( 
    <ModalButtonFooterContent  children={newChildren}></ModalButtonFooterContent>
  ); 
};
 
