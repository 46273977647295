import { styled, useStyletron } from "baseui";
import { CardProps } from "baseui/card";

interface PlainCardHeaderProps extends CardProps {
    backgroundColor?: string;
}

 
const PlainCardHeader = styled("header",(props: PlainCardHeaderProps) => {
  const { backgroundColor } = props;
  const [css, theme] = useStyletron();
  return {
    position:"relative",
    boxSizing:"border-box", 
    marginTop: `-${theme.sizing.scale900}`, 
    marginLeft:`-${theme.sizing.scale600}`, 
    marginRight:`-${theme.sizing.scale600}`, 
    borderRadius: `${theme.borders.radius400} ${theme.borders.radius400} 0 0`,
    paddingTop:"25px",
    paddingBottom: "15px", 
    ...theme.typography.LabelLarge,
    zIndex:1,
    backgroundColor: backgroundColor || "transparent",
  };});
  


export default PlainCardHeader;