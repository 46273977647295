import { FunctionComponent, useEffect } from "react";
import { ReduxService } from "../../../core/services/Redux";
import ListPlants from "../../../shared/components/list-plants/ListPlants";
import { Themes } from "../../../shared/enums/Themes.enum";



 
const FindPlants: FunctionComponent = () => {
  useEffect(() => {
    ReduxService.instance.setTheme(Themes.Plant);
  }, []);
  return ( <ListPlants/> );
};
 
export default FindPlants;