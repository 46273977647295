import { FunctionComponent } from "react";
import { GiPlantSeed } from "react-icons/gi";
import { RiSeedlingFill } from "react-icons/ri";
import { GrowthProgress } from "../../enums/GrowthProgress.enum";
import { PotShape } from "../../enums/PotShape.enum";
import { PotType } from "../../interfaces/PotType.interface";
import ContentWrapper from "../styled/ContentWrapper";
import "./Pot.css";
import PotBed from "./PotBed";
import PotSlot from "./PotSlot";

interface PotProps {
  potType: PotType;
  potRows?: GrowthProgress[][];
  progress?: GrowthProgress;
  scale?: number;
}

const Pot: FunctionComponent<PotProps> = (props) => {
  const { potType, potRows, progress, scale } = props;

  const getMaxPotSlots = (potType: PotType): number => {
    const rows = potType.slotsOrder?.length || 0;
    let columns = 0;
    potType.slotsOrder?.forEach((row) => {
      if (row > columns) {
        columns = row;
      }
    });
    if (rows > columns) {
      return rows;
    }
    return columns;
  };
  const getPotRows = (slots: number[]): GrowthProgress[][] => {
    if (potRows) {
      return potRows;
    }
    return slots.map((slot) => {
      const slots = [];
      for (let index = 0; index < slot; index++) {
        slots.push(progress || GrowthProgress.None);
      }
      return slots;
    });
  };

  const getGrowth = (progress: GrowthProgress | undefined) => {
    switch (progress) {
      case GrowthProgress.None:
        return <></>;
      case GrowthProgress.Seed:
        return <GiPlantSeed></GiPlantSeed>;
      case GrowthProgress.Seedling:
        return <RiSeedlingFill></RiSeedlingFill>;
      case GrowthProgress.Plant:
        return <RiSeedlingFill></RiSeedlingFill>;
      default:
        return <RiSeedlingFill></RiSeedlingFill>;
    }
  };

  return potType.shape === PotShape.Bed ? (
    <ContentWrapper
      $noPad
      $flex
      $noMargin
      $flexColumn
      className="multi-pot-wrapper"
    >
      <div className="multi-pot-row">
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
      </div>
      <div className="multi-pot-row">
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
      </div>
      <div className="multi-pot-row">
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
        <PotBed progress={progress}>
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotBed>
      </div>
    </ContentWrapper>
  ) : potType.multi ? (
    <ContentWrapper
      $noPad
      $noMargin
      $flex
      $flexColumn
      className="multi-pot-wrapper"
      style={{
        transform: `scale(${scale}%)`,
        maxWidth: `${scale && scale}px`,
      }}
    >
      {potType.slotsOrder &&
        getPotRows(potType.slotsOrder).map((row, rowIndex) => (
          <div key={`row-${rowIndex}`} className="multi-pot-row">
            {row.map((slot, index) => (
              <PotSlot
                scale={50 / getMaxPotSlots(potType)}
                key={index}
                progress={slot}
                shape={potType.shape}
                size={potType.slotSize}
                height={potType.height}
              >
                <span className="pot-plant">{getGrowth(slot)}</span>
              </PotSlot>
            ))}
          </div>
        ))}
      <div className="multi-pot-row" style={{ height: `${potType.height}px` }}>
        &nbsp;
      </div>
    </ContentWrapper>
  ) : (
    <ContentWrapper
      $noPad
      $noMargin
      $flex
      style={{
        flex: 0,
        width: `${scale ? (50 * scale) / 100 : 50}px`,
        margin: "0 2px",
      }}
    >
      <div style={{ transform: `scale(${scale}%)` }}>
        <PotSlot
          scale={50}
          shape={potType.shape}
          size={potType.slotSize}
          height={potType.height}
          progress={progress}
        >
          <span className="pot-plant">{getGrowth(progress)}</span>
        </PotSlot>
      </div>
    </ContentWrapper>
  );
};

Pot.defaultProps = {
  scale: 100,
};

export default Pot;
