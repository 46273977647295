import { styled } from "baseui";
import { colors } from "../../../core/theme/Colors";
import { GrowthProgress } from "../../enums/GrowthProgress.enum";


interface PotBedProps {
    progress?: GrowthProgress;
}
 
const PotBed = styled("div", (props: PotBedProps) => {
  const color = (): string => {
    switch(props.progress){
    case GrowthProgress.Seed: 
      return colors.terracotta.terracotta200;
    case GrowthProgress.Seedling: 
      return colors.plant.plant400; 
    case GrowthProgress.Plant: 
      return colors.plant.plant500; 
    default:
      return colors.plant.plant400;
    }
  };
  const fontSize = (): number => {
    switch(props.progress){
    case GrowthProgress.Seed: 
      return 15;
    case GrowthProgress.Seedling: 
      return 25; 
    case GrowthProgress.Plant: 
      return 100; 
    default:
      return 16;
    }
  };
  return {
    position: "relative",
    width:"50px",
    height:"60px",
    marginBottom:"-14px",
    marginRight: "-4px",
    color:color(),
    fontSize:`${fontSize()}px`,
    backgroundColor: colors.earth.earth500,
    borderRadius: "5px",
    boxSizing: "border-box",
    display: "flex",
    alignItems:"flex-end",
    justifyContent: "center",
    paddingBottom: `${30 - fontSize()/10}px`,
    "::after": {
      content:"' '",
      boxSizing: "border-box",
      position:"absolute",
      top:0,
      margin: 0,
      padding: 0,
      width:"50px",
      height:"50px",
      borderRadius: "5px",
      borderWidth: "1px",
      borderStyle:"solid",
      borderColor: colors.earth.earth500,
      backgroundColor: colors.earth.earth400,
        
    }
  };
}
);
 
export default PotBed;