import { ThemeProvider, useStyletron } from "baseui";
import { Button, SHAPE, SIZE } from "baseui/button";
import {
  HeaderNavigation, StyledNavigationItem, StyledNavigationList
} from "baseui/header-navigation";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import * as React from "react";
import { GiPlantSeed } from "react-icons/gi";
import { RiSeedlingFill, RiUser3Fill } from "react-icons/ri";
import { CultureTheme } from "../../core/theme/CultureTheme";
import { PlantTheme } from "../../core/theme/PlantTheme";
import { UserTheme } from "../../core/theme/UserTheme";
import ContentWrapper from "../../shared/components/styled/ContentWrapper";
import List from "../../shared/components/styled/List";
import CultureMenu from "./components/culture-menu/CultureMenu";
import PlantMenu from "./components/plant-menu/PlantMenu";
import UserMenu from "./components/user-menu/UserMenu";
import "./TopBar.css";
import {ReactComponent as Logo} from "../../assets/images/logo.svg";
interface TopBarProps  {
    onLogout: () => void
}

const TopBar = (props: TopBarProps) => {
  const [css, theme] = useStyletron();
  const { onLogout} = props;

 


  return  (
    <ContentWrapper $flex $flexJustify="flex-start"  >
      <section>
        <Logo width="50px" className={css({marginBottom:"-100%"})}/>
      </section>
     
      <section>
        <HeaderNavigation overrides={{Root:{style:{borderBottomWidth:0}}}}>
          <StyledNavigationList >
            <StyledNavigationItem>
              <ThemeProvider theme={PlantTheme}>
                <StatefulPopover placement={PLACEMENT.bottom} content={(props) => (<PlantMenu {...props}/>)}>
                  <Button  shape={SHAPE.circle} size={SIZE.compact}><GiPlantSeed></GiPlantSeed> </Button>
                </StatefulPopover>
              </ThemeProvider>
            </StyledNavigationItem>
            <StyledNavigationItem>
              <ThemeProvider theme={CultureTheme}>
                <StatefulPopover placement={PLACEMENT.bottom} content={(props) => (<CultureMenu {...props}/>)}>
                  <Button  shape={SHAPE.circle} size={SIZE.compact}><RiSeedlingFill></RiSeedlingFill> </Button>
                </StatefulPopover>
              </ThemeProvider>
            </StyledNavigationItem>
           
          </StyledNavigationList>
        </HeaderNavigation>
      </section>
      <section className="last">
        <List>
          <StyledNavigationItem>
            <ThemeProvider theme={UserTheme}>
              <StatefulPopover placement={PLACEMENT.bottom}  content={(props) => (<UserMenu {...props} onLogout={onLogout} />)}>
                <Button  shape={SHAPE.circle} size={SIZE.compact}><RiUser3Fill></RiUser3Fill> </Button>
              </StatefulPopover>
            </ThemeProvider>
          </StyledNavigationItem>
        </List >
      </section>
    
    </ContentWrapper>
  );
};

export default TopBar;