import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Cell, Grid } from "baseui/layout-grid";
import { HeadingMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReduxService } from "../../../core/services/Redux";
import Btn from "../../../shared/components/styled/Btn";
import ContentWrapper from "../../../shared/components/styled/ContentWrapper";
import InputWrapper from "../../../shared/components/styled/InputWrapper";
import PlainCard from "../../../shared/components/styled/plain-card/PlainCard";
import PlainCardFooter from "../../../shared/components/styled/plain-card/PlainCardFooter";
import { Themes } from "../../../shared/enums/Themes.enum";
import { PlantSubCategory } from "../../../shared/interfaces/PlantSubCategory.interface";
import {
  getPlantCategory,
  savePlantCategory,
  updatePlantCategory,
} from "../../../shared/services/plant-categories/PlantCategoriesService";
import { updatePlantSubCategories } from "../../../shared/services/plant-sub-categories/PlantSubCategoriesService";
import AddSubCategory from "./components/add-sub-category/AddSubCategory";
import DeleteSubCategory from "./components/delete-sub-category/DeleteSubCategory";

const EditPlantCategory = () => {
  const [name, setName] = useState<string>("");
  const [openAddSubCat, setOpenAddSubCat] = useState<boolean>(false);
  const [subCats, setSubCats] = useState([{ name: "", id: "" }]);
  const [subCatToDelete, setSubCatToDelete] =
    useState<Partial<PlantSubCategory> | null>(null);
  const { catId } = useParams();
  const category = getPlantCategory(catId || "");
  const nav = useNavigate();

  useEffect(() => {
    ReduxService.instance.setTheme(Themes.Plant);
  }, []);

  useEffect(() => {
    if (catId === "new") {
      console.log("NEW");
    } else if (catId && category) {
      setName(category.name);
      if (category.subCategories) {
        const subCats = category.subCategories.map((subcat) => {
          return { name: subcat.name, id: subcat.id };
        });
        setSubCats(subCats);
      } else {
        setSubCats([]);
      }
    }
  }, [catId, category]);

  const saveCategory = () => {
    if (catId && catId !== "new") {
      updatePlantCategory(catId, { name: name }).then(() => {
        updatePlantSubCategories(subCats).then(() => {
          nav("/plants/list");
        });
      });
    }
    if (catId === "new")
      savePlantCategory({ name: name }).then((data) => {
        nav(`/plants/category/${data.id}`);
      });
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleSubCatChange = (index: number, event: any) => {
    const cats = subCats;
    cats[index] = { ...cats[index], name: event.target.value };
    setSubCats([...cats]);
  };

  const handleAddSubCat = () => {
    setOpenAddSubCat(true);
  };

  return !catId ? (
    <div>Loading</div>
  ) : (
    <Grid>
      <Cell span={[12]}>
        <ContentWrapper $noPad>
          {catId === "new" ? (
            <HeadingMedium>Ny kategori</HeadingMedium>
          ) : (
            <HeadingMedium>Redigerar {category.name}</HeadingMedium>
          )}
        </ContentWrapper>
      </Cell>
      <Cell span={[12]}>
        <ContentWrapper $noPad>
          <PlainCard>
            <FormControl label="Namn">
              <Input
                id="category-name"
                key={catId}
                name="name"
                type="text"
                value={name}
                onChange={handleNameChange}
              ></Input>
            </FormControl>
          </PlainCard>
        </ContentWrapper>
      </Cell>

      {catId !== "new" && (
        <Cell span={[12]}>
          <ContentWrapper $noPad>
            <PlainCard>
              <FormControl label="Underkategorier">
                <>
                  {subCats.map((subcat, index) => (
                    <InputWrapper key={subcat.id}>
                      <Input
                        name={`name-${index.toString()}`}
                        type="text"
                        value={subcat.name}
                        onChange={(event) => handleSubCatChange(index, event)}
                      ></Input>
                      <Btn
                        color="danger"
                        onClick={() => setSubCatToDelete(subcat)}
                      >
                        Ta bort
                      </Btn>
                    </InputWrapper>
                  ))}
                </>
              </FormControl>
              <PlainCardFooter>
                <Btn color="success" onClick={handleAddSubCat}>
                  Lägg till
                </Btn>
              </PlainCardFooter>
            </PlainCard>
          </ContentWrapper>
        </Cell>
      )}

      <Cell span={[12]}>
        <ContentWrapper>
          <Btn disabled={!name} color="success" onClick={saveCategory}>
            SPARA
          </Btn>
        </ContentWrapper>
        <AddSubCategory
          parentId={catId}
          isOpen={openAddSubCat}
          setIsOpen={(value) => setOpenAddSubCat(value)}
        ></AddSubCategory>
        <DeleteSubCategory
          subCat={subCatToDelete}
          setIsOpen={setSubCatToDelete}
          isOpen={!!subCatToDelete}
        ></DeleteSubCategory>
      </Cell>
    </Grid>
  );
};

export default EditPlantCategory;
