import { styled } from "baseui";
import { colors } from "../../../core/theme/Colors";
import { GrowthProgress } from "../../enums/GrowthProgress.enum";
import { PotShape } from "../../enums/PotShape.enum";

interface PotSlotProps {
  shape?: PotShape;
  size?: number;
  height?: number;
  color?: string;
  progress?: GrowthProgress;
  scale?: number;
}

const PotSlot = styled("div", (props: PotSlotProps) => {
  const scale = props.scale || 100;
  const shape = props.shape || PotShape.Round;
  const size = props.size || 24;
  const height = props.height
    ? scale * (props.height / size)
    : scale * (12 / size);
  const potColor = props.color || colors.terracotta.terracotta400;
  const color = (): string => {
    switch (props.progress) {
      case GrowthProgress.Seed:
        return colors.terracotta.terracotta200;
      case GrowthProgress.Seedling:
        return colors.plant.plant400;
      case GrowthProgress.Plant:
        return colors.plant.plant500;
      default:
        return colors.plant.plant400;
    }
  };
  const fontSize = (): number => {
    switch (props.progress) {
      case GrowthProgress.Seed:
        return 15;
      case GrowthProgress.Seedling:
        return 25;
      case GrowthProgress.Plant:
        return 100;
      default:
        return 16;
    }
  };
  return {
    position: "relative",
    width: `${scale}px`,
    height: `${scale + height}px`,
    marginBottom: `-${height + (4 * scale) / 100}px`,
    marginRight: `${(-4 * scale) / 100}px`,
    color: color(),
    fontSize: `${fontSize()}px`,
    backgroundColor: potColor,
    borderRadius:
      shape === PotShape.Round ? `${scale}px` : `${(5 * scale) / 100}px`,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    lineHeight: "0",
    paddingBottom: `${height + scale / 2 - fontSize() / 6}px`,
    boxShadow: `${(2 * scale) / 100}px 0px ${
      (2 * scale) / 100
    }px rgba(0,0,0,0.2) inset, ${(-1 * scale) / 100}px ${(3 * scale) / 100}px ${
      (8 * scale) / 100
    }px ${(2 * scale) / 100}px rgba(0,0,0,0.12)`,
    "::after": {
      content: "' '",
      boxSizing: "border-box",
      position: "absolute",
      top: 0,
      margin: 0,
      padding: 0,
      width: "100%",
      maxHeight: `${scale}px`,
      height: "100%",
      borderRadius:
        shape === PotShape.Round ? "100%" : `${(5 * scale) / 100}px`,
      borderWidth: `${(3 * scale) / 100}px`,
      borderStyle: "solid",
      borderColor: potColor,
      backgroundColor: colors.earth.earth600,
    },
  };
});

export default PotSlot;
