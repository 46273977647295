import { ThemeProvider, useStyletron } from "baseui";
import { SHAPE, SIZE } from "baseui/button";
import { Cell, Grid } from "baseui/layout-grid";
import { HeadingSmall } from "baseui/typography";
import { FunctionComponent, useEffect, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { RiAddFill, RiMoreFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { CultureTheme } from "../../../core/theme/CultureTheme";
import { getMyCultures } from "../../services/cultures/CulturesService";
import Btn from "../styled/Btn";
import ContentWrapper from "../styled/ContentWrapper";
import PlainCard from "../styled/plain-card/PlainCard";
import CultureContent from "./components/culture-content/CultureContent";

interface ListCulturesProps {
  backgroundColor?: string;
  hideAddNew?: boolean;
  hideActions?: boolean;
  span?: number[];
  perPage?: number;
  startOpen?: boolean;
}

const ListCultures: FunctionComponent<ListCulturesProps> = (props) => {
  const { backgroundColor, hideAddNew, hideActions, span, perPage, startOpen } =
    props;
  const cultures = getMyCultures();
  const [css, theme] = useStyletron();
  const [numbersToShow, setNumbersToShow] = useState<number>(0);
  const nav = useNavigate();

  useEffect(() => {
    if (numbersToShow > 0) {
      setNumbersToShow(numbersToShow);
    } else if (perPage) {
      setNumbersToShow(perPage);
    } else if (cultures) {
      setNumbersToShow(cultures.length);
    }
  }, [perPage, cultures]);

  const addCulture = () => {
    nav("/culture/new");
  };

  return !cultures ? (
    <div>Loading</div>
  ) : (
    <ThemeProvider theme={CultureTheme}>
      <Grid>
        {cultures.slice(0, numbersToShow).map((culture) => (
          <Cell span={span} key={culture.id}>
            <ContentWrapper $noPad>
              <CultureContent
                startOpen={startOpen || false}
                culture={culture}
                hideActions={hideActions || false}
                backgroundColor={backgroundColor || theme.colors.white}
              />
            </ContentWrapper>
          </Cell>
        ))}
        {!hideAddNew && !perPage && (
          <Cell span={span}>
            <ContentWrapper $noPad>
              <PlainCard
                $backgroundColor={backgroundColor || theme.colors.white}
              >
                <ContentWrapper>
                  <header
                    className={css({
                      boxSizing: "border-box",
                      marginTop: "-25px",
                      marginLeft: "-25px",
                      marginRight: "-25px",
                      paddingTop: "25px",
                      paddingBottom: "15px",
                    })}
                  >
                    <HeadingSmall>Lägg till odling</HeadingSmall>
                  </header>
                  <Btn
                    color="success"
                    kind="primary"
                    shape={SHAPE.circle}
                    onClick={addCulture}
                  >
                    <RiAddFill></RiAddFill>
                  </Btn>
                </ContentWrapper>
              </PlainCard>
            </ContentWrapper>
          </Cell>
        )}
        {perPage && (
          <Cell span={span}>
            <ContentWrapper $noPad>
              {cultures.length > numbersToShow ? (
                <Btn
                  color="success"
                  size={SIZE.large}
                  kind="primary"
                  shape={SHAPE.circle}
                  onClick={() => setNumbersToShow(numbersToShow + perPage)}
                >
                  <RiMoreFill />
                </Btn>
              ) : (
                <Btn
                  color="success"
                  size={SIZE.large}
                  kind="primary"
                  shape={SHAPE.circle}
                >
                  <GiCheckMark />
                </Btn>
              )}
            </ContentWrapper>
          </Cell>
        )}
      </Grid>
    </ThemeProvider>
  );
};

ListCultures.defaultProps = {
  span: [4, 4, 4],
};

export default ListCultures;
