
import { KIND } from "baseui/button";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { Option, Select } from "baseui/select";
import { Slider } from "baseui/slider";
import { FunctionComponent, useState } from "react";
import { GrFormAdd, GrFormSubtract } from "react-icons/gr";
import { GrowthProgress } from "../../enums/GrowthProgress.enum";
import { PotShape } from "../../enums/PotShape.enum";
import { PotType } from "../../interfaces/PotType.interface";
import { savePotType } from "../../services/pot-types/PotTypesService";
import Pot from "../pot/Pot";
import Btn from "../styled/Btn";
import ContentWrapper from "../styled/ContentWrapper";
import ModalButtonFooter from "../styled/modal/ModalButtonFooter";
import "./AddPot.css";

interface AddPotProps {
    isOpen: boolean;
    onClose:() => void;
}
const initials = {
  multi:false,
  shape: [{id:PotShape.Round, label:"Rund"}],
  height: [25],
  slotSize: [75],
  slotsOrder: [{id:"0", value:3}],
  progress: GrowthProgress.Seedling,
};

const AddPot: FunctionComponent<AddPotProps> = (props) => {
  const {isOpen, onClose} = props;
  const [multi, setMulti] = useState<boolean>(initials.multi);
  const [shape, setShape] = useState<Option[]>(initials.shape);
  const [height, setHeight] = useState<number[]>(initials.height);
  const [slotSize, setSlotSize] = useState<number[]>(initials.slotSize);
  const [slotsOrder, setSlotsOrder] = useState<{id:string, value:number}[]>(initials.slotsOrder);
  const [progress, setProgress] = useState<GrowthProgress>(initials.progress);
  
  const pot = (): PotType => {
    return {
      multi,
      shape: shape[0].id as PotShape,
      height: height[0],
      slotSize: slotSize[0],
      slotsOrder: slotsOrder.map(slot => slot.value),
      owner: "",
      id:"",
    };
  };

  const resetAll = () => {
    setMulti(initials.multi);
    setShape(initials.shape);
    setHeight(initials.height);
    setSlotSize(initials.slotSize);
    setSlotsOrder(initials.slotsOrder);
    setProgress(initials.progress);
  };

  const cancel = () => {
    resetAll();
    onClose();
  };

  const handleMultiChange = (e:any) => {
    setMulti(e.target.checked);
  };

  const handleSlotsOrderChange = (e:any, index:number) => {
    const slots = [...slotsOrder];
    slots[index] = {...slots[index], value:+e.target.value};
    setSlotsOrder(slots);
  };

  const handleAddSlotsOrderRow = () => {
    const slots = [...slotsOrder];
    slots.push({id:slots.length.toString(), value:slots[0].value});
    setSlotsOrder(slots);
  };

  const handleRemoveSlotsOrderRow = () => {
   
    const slots = [...slotsOrder];
    slots.splice(slots.length-1, 1);
    setSlotsOrder([...slots]);
    
  };

  const onSubmit = () => {
    savePotType({
      multi,
      shape: shape[0].id as PotShape,
      height: height[0],
      slotSize: slotSize[0],
      slotsOrder: slotsOrder.map(slot => slot.value),
    }).then(() => {
      resetAll();
      onClose();
    });
  };

  return ( 

    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="auto"
    >
      <ModalHeader>Ny kruka</ModalHeader>
      <ModalBody>
     
        <ContentWrapper className="pot-wrapper">
          <Pot potType={pot()} progress={progress}></Pot>
        </ContentWrapper>
        <ContentWrapper className="form-wrapper">
          <FormControl label="Förhandsvisa med:">
            <RadioGroup value={progress} onChange={(e:any) => setProgress(e.currentTarget.value)} name="progress" align={ALIGN.horizontal}>
              <Radio value={GrowthProgress.None}>Inget</Radio>
              <Radio value={GrowthProgress.Seed}>Frö</Radio>
              <Radio value={GrowthProgress.Seedling}>Grodd</Radio>
              <Radio value={GrowthProgress.Plant}>Planta</Radio>
            </RadioGroup>
          </FormControl>
       
          <FormControl label="Storlek">
            <Slider
              value={slotSize}
              onChange={({ value }) => value && setSlotSize(value)}
              min={30}
              max={200}
            
            />
          </FormControl>
          <FormControl label="Höjd">
            <Slider
              value={height}
              onChange={({ value }) => value && setHeight(value)}
              min={5}
              max={200}
            
            />
          </FormControl>
          <FormControl label="Form">
            <Select 
              backspaceRemoves={false}
              clearable={false}
              deleteRemoves={false}
              escapeClearsValue={false}
              required
              value={shape} 
              onChange={params => setShape(params.value as Option[])} 
              options={[{id:PotShape.Round, label:"Rund"}, {id:PotShape.Square, label:"Kantig"}]}
            ></Select>
          </FormControl>
          <FormControl label="Multikruka?">
            <Checkbox
              onChange={handleMultiChange}
              checked={multi}
              checkmarkType={STYLE_TYPE.toggle_round}
              labelPlacement={LABEL_PLACEMENT.right}
            >
      Har krukan flera pottar?
            </Checkbox>
          </FormControl>
          {multi && (
            <div>

              {slotsOrder.map((slot, index) => (
                <FormControl key={slot.id} label={`Antal pottar i rad ${index+1}`}>
                  <Input type="number" value={slot.value} onChange={(e) => handleSlotsOrderChange(e, index)}></Input>
                </FormControl>
              ))}
              <Btn color="danger" disabled={slotsOrder.length < 2} onClick={handleRemoveSlotsOrderRow}><GrFormSubtract></GrFormSubtract><span>Ta bort rad</span></Btn>
              <Btn color="success" onClick={handleAddSlotsOrderRow}><GrFormAdd></GrFormAdd><span>Lägg till rad</span></Btn>
            </div>
          )}
        </ContentWrapper>
      </ModalBody>
      <ModalButtonFooter>
        <Btn color="accent" kind={KIND.tertiary} onClick={cancel}>
              Avbryt
        </Btn>
        <Btn color="success" onClick={onSubmit}>Spara</Btn>
      </ModalButtonFooter>
    </Modal>
  );
};
 
export default AddPot;