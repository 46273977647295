import { Button, SHAPE } from "baseui/button";
import { Cell, Grid } from "baseui/layout-grid";
import { FunctionComponent } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import ContentWrapper from "../../shared/components/styled/ContentWrapper";

interface StartProps {
    onSignIn: () => void;
}
 
const Start: FunctionComponent<StartProps> = (props) => {
  const { onSignIn} = props;
    
  return (
    <Grid>
      <Cell span={[12]}>
        <ContentWrapper>
          <Logo width="100px"/>
        </ContentWrapper>
      </Cell>
      <Cell span={[12]}>
        <ContentWrapper>
          <Button shape={SHAPE.pill} onClick={onSignIn}>Logga in</Button>
        </ContentWrapper>
      </Cell>
    </Grid>  
  ) ;
};
 

export default Start;